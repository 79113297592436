import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

// TODO: change to true once it goes to PROD
Vuetify.config.silent = false

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: {},
    },
    theme: {
        dark: false,
        default: 'light',
        options: {customProperties: true},
        themes: {
            light: {
                // primary: "#9652ff"
                
                // Defaults as per https://vuetifyjs.com/en/features/theme/#customizing
                //primary: '#1976D2',
                //secondary: '#424242',
                //accent: '#82B1FF',
                //error: '#FF5252',
                //info: '#2196F3',
                //success: '#4CAF50',
                //warning: '#FFC107',

                amigos_green: "#437613",        // green background rgb(67, 118, 19)
                amigos_light_green: "#6DA834",  // light green background: #6DA834, rgb(109, 168, 52)
                amigos_purple: "#993166",       // Less frequently used purple background: #993166, rgb(153, 49, 102)

                primary: "#437613",
                secondary: "#6DA834",
                tertiary: "#993166",
                accent: "#82ffdc", // Mostly used for small clickable icons in chat messages
                anchor: '#21e0f3' // this is the color <a href> links are shown in the chat message (need a better way to set?)
           }
        }
    }
});
