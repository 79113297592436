

















































































import { Component, Vue } from 'vue-property-decorator';
import store from './store';
import router from './router';
import { REST } from './_util/rest_call'
import { DATA } from './_util/data'
import Net from '../../common/net_interface'
import DB from '../../common/db_struct';

@Component({
  name: 'App',
  data() {
      return {
        snackbar:false,
        snackbar_timeout:3000,
        snackbar_text: "Something happened",
        appTitle: this.$t('app_title'),
        selChatRoomId: -1,
        wsConnected:false,
        loggedIn:false,
        unsubscribe:null
      }
  },
  methods: {
      logout() {
          REST.logDebug("logout:", REST.getLogonData());
          this.$data.appTitle = this.$t('app_title');
          this.$data.selChatRoomId = -1;
          document.cookie = "";
          REST.setLogonData(null);
          store.dispatch('clearAll');
          this.$router.push({path:"/login"});
          return false;
      },
      openNav() {
        store.commit('setSideMenuOpen', !store.getters.sideMenuOpen);
      },
      canEditTitle() {
        let rv = false;
        let roomId = this.$data.selChatRoomId;
        if (roomId > 0) {
          let room = store.getters.getRoom(roomId) as Net.ChatRoomAndMembers;
          if (room) {
            rv = (!DATA.isSystemRoom(room)
                    && ((room.creator_id == REST.userId())
                        || (REST.isAdmin())));
          }
        }
        return rv;
      },
      updateRoomTitle() {
        let roomId = this.$data.selChatRoomId;
        REST.logDebug("updateRoomTitle: ", roomId);
        let room = store.getters.getRoom(roomId) as Net.ChatRoomAndMembers;
        if (room) {
          // set members
          let url = "/messages/rooms/" + room.id.toString();
          let body = new DB.Chatroom(room);
          body.title = this.$data.appTitle;
          REST.call("PUT", url,
                    body,
                    (r => { REST.logDebug("UpdateRoomTitle:", r);
                        store.dispatch("updateRoom", -room.id);
                        return r.detail;
                    }),
                    (async (r) => { 
                      // GlobalSnackBarFn(r.toString());
                      REST.logError(" SaveGroup:", r);
                    }));
        }
      }
  },
  created() {
    REST.logDebug("APP: created");
    this.$data.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'setAppTitle') {
        this.$data.appTitle = store.getters.appTitle;
      }
      if (mutation.type === 'setSelChatRoomId') {
        this.$data.selChatRoomId = store.getters.selChatRoomId;
      }
      if (mutation.type === 'setWsState')
      {
        this.$data.wsConnected = store.getters.isWsConnected;
        this.$data.loggedIn = REST.isLoggedIn();
      }
    });
  },
  beforeDestroy() {
    this.$data.unsubscribe();
  },
  beforeMount() {
    REST.setSnackbarFn((text:string, timeout?:number) => {
                          REST.logDebug("SNACKBAR", text, timeout);
                          this.$data.snackbar_text = text;
                          if (timeout) {
                            this.$data.snackbar_timeout = timeout;
                          } else {
                            this.$data.snackbar_timeout = 2000;
                          }
                          this.$data.snackbar = true;
                        });
    REST.setLogoutFn(() => {
                          REST.logDebug("logout:", REST.getLogonData());
                          this.$data.appTitle = this.$t('app_title');
                          this.$data.selChatRoomId = -1;
                          document.cookie = "";
                          REST.setLogonData(null);
                          store.dispatch('clearAll');
                          this.$router.push({path:"/login"});
                        });

    this.$data.wsConnected = store.getters.isWsConnected;
    this.$data.loggedIn = REST.isLoggedIn();
  }
})
export default class App extends Vue {}

