import DB from '../../../common/db_struct';
import Net from '../../../common/net_interface'

export const REST = {
    setLogoutFn,
    setSnackbarFn,
    call,
    documentLink,
    downloadDocument,
    downloadMeetingDocs,
    downloadMeetingReplies,
    downloadTaskReplies,
    downloadKidUsers,
    downloadNonKidUsers,
    setLogonData,
    getLogonData,
    wsSessionToken,
    restSessionToken,
    wsURL,
    userId,
    isAdmin,
    isKid,
    isLoggedIn,
    isAmigo,
    logError,
    logWarning,
    logInfo,
    logDebug
};

export var GlobalSnackBarFn : (text:string, timeout?:number) => void;
export var GlobalLogoutFn : () => void;

// logging
export const ERROR_LOG_LEVEL    = 1;
export const WARNING_LOG_LEVEL  = 2;
export const INFO_LOG_LEVEL     = 3;
export const DEBUG_LOG_LEVEL    = 4;
export var logLevel : number = 2;

const RE_IsoDateFormat = new RegExp('^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[\.\d{3}]');
const dateToIsoStr = (d: Date) => {
    return d.toISOString().replace('Z', '');
}
const logPrefix = () => {
    return dateToIsoStr(new Date()).replace('T', ' ');
}
function logError(message?: any, ...optionalParams: any[]) {
    if (logLevel >= ERROR_LOG_LEVEL) {
        console.log(logPrefix() + " ERROR ", message, ...optionalParams);
    }
}
function logWarning(message?: any, ...optionalParams: any[]) {
    if (logLevel >= WARNING_LOG_LEVEL) {
        console.log(logPrefix() + " WARNING ", message, ...optionalParams);
    }
}
function logInfo(message?: any, ...optionalParams: any[]) {
    if (logLevel >= INFO_LOG_LEVEL) {
        console.log(logPrefix() + " INFO ", message, ...optionalParams);
    }
}
function logDebug(message?: any, ...optionalParams: any[]) {
    if (logLevel >= DEBUG_LOG_LEVEL) {
        console.log(logPrefix() + " DEBUG ", message, ...optionalParams);
    }
}

function setLogoutFn(fn: () => void) {
    GlobalLogoutFn = fn;
}
function setSnackbarFn(fn: (text:string, timeout?:number) => void) {
    GlobalSnackBarFn = fn;
}

function setLogonData(data: any | null) {
    if (data == null) {
        localStorage.removeItem("session");
        localStorage.removeItem("restToken");
        localStorage.removeItem("userid");
        localStorage.removeItem("isAdmin");
        localStorage.removeItem("isAmigo");
        localStorage.removeItem("isKid");
    } else {
        let session = (data as Net.Login_Resp)
        // REST.logDebug("type:", typeof data, "data", data);
        localStorage.setItem("session", JSON.stringify(session));
        localStorage.setItem("restToken", session.rest_api_token);
        localStorage.setItem("userid", session.id.toString());
        localStorage.setItem("isAdmin", (session.is_admin ? "Y" : "N"));
        localStorage.setItem("isAmigo", (session.is_amigo ? "Y" : "N"));
        localStorage.setItem("isKid", (session.is_kid ? "Y" : "N"));
    }
}
function getLogonData() {
    let data = localStorage.getItem("session");
    if (data) {
        return (JSON.parse(data) as Net.Login_Resp);
    }
    return null;
}
function wsSessionToken() {
    return getLogonData()?.ws_api_token;
}
function restSessionToken() {
    return localStorage.getItem("restToken");
}
function userId() {
    return Number(localStorage.getItem("userid"));
}
function isLoggedIn() {
    const loggedIn = REST.userId();
    return (loggedIn!=null) && (Number(loggedIn) > 0);
}
function isAdmin() {
    return localStorage.getItem("isAdmin") === "Y";
}
function isAmigo() {
    return localStorage.getItem("isAmigo") === "Y";
}
function isKid() {
    return localStorage.getItem("isKid") === "Y";
}
function wsURL() {
    let rv = document.location.hostname + ":";
    if (process.env.NODE_ENV === 'production') {
        if (document.location.protocol.toUpperCase().startsWith("HTTPS")) {
            rv = "wss://" + rv + document.location.port + "/ws/";
        } else {
            rv = "ws://" + rv + document.location.port + "/ws/";
        }
    } else {
        rv = "ws://" + rv + "8990/ws/"
    }
    // REST.logDebug("wsURL:", rv);
    return rv;
}
function restURL() {
    let rv = document.location.protocol + "//"
            + document.location.hostname + ":"
    if (process.env.NODE_ENV === 'production') {
        rv += document.location.port + "/api";
    } else {
        rv += "3000/api"
    }
    // REST.logDebug("BaseURL:", rv);
    return rv;
}
function documentLink(id:number)
{
    // REST.logDebug("documentLink for id=", id);
    return restURL() + "/docs/download/" + id.toString();
}
function downloadDocument(docId:number)
{
    // REST.logDebug("downloadDocument", doc);
    window.open(documentLink(docId));
}
function downloadMeetingDocs(id:number)
{
    window.open(restURL() + "/meetings/docs/" + id.toString());
}
function downloadMeetingReplies(id:number)
{
    window.open(restURL() + "/tasks/meetingreplies/" + id.toString());
}
function downloadTaskReplies(id:number)
{
    window.open(restURL() + "/tasks/replies/" + id.toString());
}
function downloadKidUsers()
{
    window.open(restURL() + "/users/downloadKids");
}
function downloadNonKidUsers()
{
    window.open(restURL() + "/users/downloadNonKids");
}

function restCall(restCallUrl: string, params : any
                , okFn : (a:any) => {}
                , errorFn? : (err:Error) => { })
{
    fetch(restCallUrl, params)
        .then(response => response.json())  // response.blob() if binary download
        .then((data) => {
            // REST.logDebug("RECEIVED [", restCallUrl,"]: ", data);
            if (data.ok) {
                okFn(data);
            } else {
                logError('REST CALL ERROR[', data.error, ']', data.detail);
                if (data.error == "AUTH") {
                    GlobalSnackBarFn("Authentication ERROR: " + data.detail.toString());
                    GlobalLogoutFn();
                }
                if (errorFn) {
                    errorFn(Error('[' + String(data.error) + ']' + String(data.detail)));
                } else {
                    GlobalSnackBarFn("REST-API error: " + data.error.toString()
                            + "\nDetails: " + data.detail.toString());
                }
            }
        }).catch((err) => {
            logError('REST FAILED', err);
            if (errorFn) {
                errorFn(err);
            } else {
                GlobalSnackBarFn("REST-API error: " + err.toString());
            }
        });
}
function sendImageData(dataURL:string, fileName:string
                    , callUrl : string
                    , okFn : (a:any) => {}
                    , errorFn? : (err:Error) => { })
{
    // fetch{ }
    let params : any = { };
    params.headers = {}
    params.headers["Access-Control-Allow-Origin"] = "*";
    let sessionToken = restSessionToken();
    if (sessionToken && sessionToken != null) {
        params.headers["session_token"] = sessionToken;
    }

    var fd = new FormData();
    fd.append("imageData", dataURL);
    fd.append("imageName", fileName);
    // Then the browser will automatically add the Content type header including the Form Boundary
    // params.headers["content-type"] = "multipart/form-data";
    params.method = "POST";
    params.body = fd;

    let restCallUrl = restURL() + callUrl;
    REST.logDebug("Upload image:", restCallUrl, " length:", dataURL.length);
    restCall(restCallUrl, params, okFn, errorFn);
}

function processImageFile(dataURL:string, fileType:string, fileName:string
                        , callUrl : string
                        , okFn : (a:any) => {}
                        , errorFn? : (err:Error) => { })
{
	let maxSide = 1024;
    REST.logDebug("Upload image.length:", dataURL.length);

	let image = new Image();
	image.src = dataURL;

	image.onload = function () {
		let width = image.width;
		let height = image.height;
		let shouldResize = (width > maxSide) || (height > maxSide);

		if (!shouldResize) {
			sendImageData(dataURL, fileName, callUrl,  okFn, errorFn);
			return;
		}

		let newWidth;
		let newHeight;

		if (width > height) {
			newHeight = height * (maxSide / width);
			newWidth = maxSide;
		} else {
			newWidth = width * (maxSide / height);
			newHeight = maxSide;
		}

		let canvas = document.createElement('canvas');
		canvas.width = newWidth;
		canvas.height = newHeight;
		let context = canvas.getContext('2d');
        if (context) {
		    context.drawImage(image, 0, 0, newWidth, newHeight);
    		dataURL = canvas.toDataURL(fileType);

	    	sendImageData(dataURL, fileName, callUrl, okFn, errorFn);
        } else {
            REST.logDebug("Failed to create image context for:", dataURL);
        }
	};

	image.onerror = function () {
		alert('There was an error processing your file!');
	};
}

function call(methodStr : string, callUrl : string
                    , obj : any
                    , okFn : (a:any) => {}
                    , errorFn? : (err:Error) => { })
{
    // fetch{ }
    let params : any = { };
    params.headers = {}
    params.headers["Access-Control-Allow-Origin"] = "*";
    let sessionToken = restSessionToken();
    if (sessionToken && sessionToken != null) {
        params.headers["session_token"] = sessionToken;
    }
    if (methodStr == "UPLOAD") {
        REST.logDebug("UPLOAD file type:", obj.type, "name:", obj.name);
        if (obj.type.toString().startsWith("image/")) {
            var reader = new FileReader();
            reader.onloadend = function () {
                if (reader.result) {
                    processImageFile(reader.result as string
                                    , obj.type, obj.name
                                    , callUrl, okFn, errorFn);
                } else {
                    alert("FileReader.result is NULL");
                }
            }

            reader.onerror = function () {
                alert('There was an error reading the file!');
            }

            reader.readAsDataURL(obj);
            return;
        }

        var fd = new FormData();
        fd.append("uploadFile", obj);
        // Then the browser will automatically add the Content type header including the Form Boundary
        // params.headers["content-type"] = "multipart/form-data";
        params.method = "POST";
        params.body = fd;
    } else if (methodStr != "GET") {
        let jsonBody : string = JSON.stringify(obj);
        params.headers["content-length"] = jsonBody.length;
        params.headers["content-type"] = "application/json;charset=UTF-8";
        params.method = methodStr;
        params.body = JSON.stringify(obj);
    } else {
        // Send JSON in Base64 encoded form
        let jsonBody : string = JSON.stringify(obj);
        jsonBody = btoa(unescape(encodeURIComponent(jsonBody)));
        params.headers["Content"] = jsonBody;
        params.headers["content-type"] = "application/json;charset=UTF-8";
        params.method = "GET";
    }

    let restCallUrl = restURL() + callUrl;
    REST.logDebug("Calling[", methodStr, "]:", restCallUrl, " body:", JSON.stringify(obj));
    restCall(restCallUrl, params, okFn, errorFn);
}

function handleResponse(response : Response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}