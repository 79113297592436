import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '../store'
import { REST } from '../_util/rest_call'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Main',
    component: () => import('../views/Main.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword.vue')
  },
  { path: '/:catchAll(.*)', redirect: '/' }

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/register','/forgotPassword'];
  const authRequired = !publicPages.includes(to.path);
  // const loggedIn = store.getters.getLoginUser();
  REST.logDebug("authRequired:", authRequired, "loggedIn:", REST.isLoggedIn());

  if (authRequired && (!REST.isLoggedIn())) {
    REST.logDebug("goto login");
    return next('/login');
  }

  store.dispatch("connectWebSocket");
  
  next();
})

export default router
