/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { REST } from './_util/rest_call'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      REST.logInfo(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      REST.logInfo('Service worker has been registered.')
    },
    cached () {
      REST.logInfo('Content has been cached for offline use.')
    },
    updatefound () {
      REST.logInfo('New content is downloading.')
    },
    updated () {
      REST.logInfo('New content is available; please refresh.')
    },
    offline () {
      REST.logInfo('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      REST.logError('Error during service worker registration:', error)
    }
  })
}
