
//
//     !!! Generated file !!!
//         DO NOT MODIFY
//

namespace DB {
    var VERSION = "20211027075348";


    //////////////////
    // Representation for the database table: amigoskids
    //////////////////
    export class Amigoskids{
        create_time          : Date = new Date(0);
        update_time          : Date = new Date(0);
        is_active            : boolean = false;
        id                   : number = 0;
        amigo_id             : number = 0;
        kid_id               : number = 0;
        updater_id           : number = 0;

        // Copy cTor
        constructor(o?:Amigoskids) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.update_time          = o.update_time;
                this.id                   = o.id;
                this.updater_id           = o.updater_id;
            }
        }

        // Content assigner
        setContent(o:Amigoskids) {
            this.is_active            = o.is_active;
            this.amigo_id             = o.amigo_id;
            this.kid_id               = o.kid_id;
        }

        // Content compare
        sameContent(o:Amigoskids) {
            return (this.is_active            == o.is_active)
                && (this.amigo_id             == o.amigo_id)
                && (this.kid_id               == o.kid_id)
        }
    }

    //////////////////
    // Representation for the database table: appuser
    //////////////////
    export class Appuser{
        create_time          : Date = new Date(0);
        update_time          : Date = new Date(0);
        date_of_birth        : Date = new Date(0);
        is_amigo             : boolean = false;
        is_admin             : boolean = false;
        is_disabled          : boolean = false;
        is_kid               : boolean = false;
        id                   : number = 0;
        avatar_doc_id        : number = 0;
        login                : string = "";
        password             : string = "";
        name                 : string = "";
        settings_json        : string = "";
        given_name           : string = "";
        family_name          : string = "";
        phone                : string = "";
        email                : string = "";
        questioner_json      : string = "";

        // Copy cTor
        constructor(o?:Appuser) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.update_time          = o.update_time;
                this.id                   = o.id;
                this.password             = o.password;
            }
        }

        // Content assigner
        setContent(o:Appuser) {
            this.date_of_birth        = o.date_of_birth;
            this.is_amigo             = o.is_amigo;
            this.is_admin             = o.is_admin;
            this.is_disabled          = o.is_disabled;
            this.is_kid               = o.is_kid;
            this.avatar_doc_id        = o.avatar_doc_id;
            this.login                = o.login;
            this.name                 = o.name;
            this.settings_json        = o.settings_json;
            this.given_name           = o.given_name;
            this.family_name          = o.family_name;
            this.phone                = o.phone;
            this.email                = o.email;
            this.questioner_json      = o.questioner_json;
        }

        // Content compare
        sameContent(o:Appuser) {
            return (this.date_of_birth        == o.date_of_birth)
                && (this.is_amigo             == o.is_amigo)
                && (this.is_admin             == o.is_admin)
                && (this.is_disabled          == o.is_disabled)
                && (this.is_kid               == o.is_kid)
                && (this.avatar_doc_id        == o.avatar_doc_id)
                && (this.login                == o.login)
                && (this.name                 == o.name)
                && (this.settings_json        == o.settings_json)
                && (this.given_name           == o.given_name)
                && (this.family_name          == o.family_name)
                && (this.phone                == o.phone)
                && (this.email                == o.email)
                && (this.questioner_json      == o.questioner_json)
        }
    }

    //////////////////
    // Representation for the database table: chatroom
    //////////////////
    export class Chatroom{
        create_time          : Date = new Date(0);
        update_time          : Date = new Date(0);
        is_public            : boolean = false;
        is_personal          : boolean = false;
        is_amigo_only        : boolean = false;
        id                   : number = 0;
        creator_id           : number = 0;
        unique_id            : string = "";
        title                : string = "";

        // Copy cTor
        constructor(o?:Chatroom) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.update_time          = o.update_time;
                this.id                   = o.id;
                this.creator_id           = o.creator_id;
            }
        }

        // Content assigner
        setContent(o:Chatroom) {
            this.is_public            = o.is_public;
            this.is_personal          = o.is_personal;
            this.is_amigo_only        = o.is_amigo_only;
            this.unique_id            = o.unique_id;
            this.title                = o.title;
        }

        // Content compare
        sameContent(o:Chatroom) {
            return (this.is_public            == o.is_public)
                && (this.is_personal          == o.is_personal)
                && (this.is_amigo_only        == o.is_amigo_only)
                && (this.unique_id            == o.unique_id)
                && (this.title                == o.title)
        }
    }

    //////////////////
    // Representation for the database table: chatroommembers
    //////////////////
    export class Chatroommembers{
        create_time          : Date = new Date(0);
        is_member_group      : boolean = false;
        is_active            : boolean = false;
        id                   : number = 0;
        room_id              : number = 0;
        member_id            : number = 0;

        // Copy cTor
        constructor(o?:Chatroommembers) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Chatroommembers) {
            this.is_member_group      = o.is_member_group;
            this.is_active            = o.is_active;
            this.room_id              = o.room_id;
            this.member_id            = o.member_id;
        }

        // Content compare
        sameContent(o:Chatroommembers) {
            return (this.is_member_group      == o.is_member_group)
                && (this.is_active            == o.is_active)
                && (this.room_id              == o.room_id)
                && (this.member_id            == o.member_id)
        }
    }

    //////////////////
    // Representation for the database table: configparameters
    //////////////////
    export class Configparameters{
        id                   : number = 0;
        name                 : string = "";
        value_str            : string = "";
        description          : string = "";

        // Copy cTor
        constructor(o?:Configparameters) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Configparameters) {
            this.name                 = o.name;
            this.value_str            = o.value_str;
            this.description          = o.description;
        }

        // Content compare
        sameContent(o:Configparameters) {
            return (this.name                 == o.name)
                && (this.value_str            == o.value_str)
                && (this.description          == o.description)
        }
    }

    //////////////////
    // Representation for the database table: document
    //////////////////
    export class Document{
        upload_time          : Date = new Date(0);
        is_deleted           : boolean = false;
        id                   : number = 0;
        ref_id               : number = 0;
        uploader_id          : number = 0;
        type_id              : number = 0;
        file_size            : number = 0;
        file_path            : string = "";
        description          : string = "";

        // Copy cTor
        constructor(o?:Document) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Document) {
            this.upload_time          = o.upload_time;
            this.is_deleted           = o.is_deleted;
            this.ref_id               = o.ref_id;
            this.uploader_id          = o.uploader_id;
            this.type_id              = o.type_id;
            this.file_size            = o.file_size;
            this.file_path            = o.file_path;
            this.description          = o.description;
        }

        // Content compare
        sameContent(o:Document) {
            return (this.upload_time          == o.upload_time)
                && (this.is_deleted           == o.is_deleted)
                && (this.ref_id               == o.ref_id)
                && (this.uploader_id          == o.uploader_id)
                && (this.type_id              == o.type_id)
                && (this.file_size            == o.file_size)
                && (this.file_path            == o.file_path)
                && (this.description          == o.description)
        }
    }

    //////////////////
    // Representation for the database table: documenttype
    //////////////////
    export class Documenttype{
        id                   : number = 0;
        description          : string = "";
        code                 : string = "";

        // Copy cTor
        constructor(o?:Documenttype) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Documenttype) {
            this.description          = o.description;
            this.code                 = o.code;
        }

        // Content compare
        sameContent(o:Documenttype) {
            return (this.description          == o.description)
                && (this.code                 == o.code)
        }
    }

    //////////////////
    // Representation for the database table: filloutform
    //////////////////
    export class Filloutform{
        create_time          : Date = new Date(0);
        update_time          : Date = new Date(0);
        is_deleted           : boolean = false;
        id                   : number = 0;
        creator_id           : number = 0;
        updater_id           : number = 0;
        name                 : string = "";

        // Copy cTor
        constructor(o?:Filloutform) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.update_time          = o.update_time;
                this.id                   = o.id;
                this.creator_id           = o.creator_id;
                this.updater_id           = o.updater_id;
            }
        }

        // Content assigner
        setContent(o:Filloutform) {
            this.is_deleted           = o.is_deleted;
            this.name                 = o.name;
        }

        // Content compare
        sameContent(o:Filloutform) {
            return (this.is_deleted           == o.is_deleted)
                && (this.name                 == o.name)
        }
    }

    //////////////////
    // Representation for the database table: filloutitem
    //////////////////
    export class Filloutitem{
        mandatory            : boolean = false;
        non_public           : boolean = false;
        id                   : number = 0;
        form_id              : number = 0;
        item_type            : number = 0;
        item_index           : number = 0;
        name                 : string = "";
        label                : string = "";
        description          : string = "";
        options              : string = "";

        // Copy cTor
        constructor(o?:Filloutitem) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Filloutitem) {
            this.mandatory            = o.mandatory;
            this.non_public           = o.non_public;
            this.form_id              = o.form_id;
            this.item_type            = o.item_type;
            this.item_index           = o.item_index;
            this.name                 = o.name;
            this.label                = o.label;
            this.description          = o.description;
            this.options              = o.options;
        }

        // Content compare
        sameContent(o:Filloutitem) {
            return (this.mandatory            == o.mandatory)
                && (this.non_public           == o.non_public)
                && (this.form_id              == o.form_id)
                && (this.item_type            == o.item_type)
                && (this.item_index           == o.item_index)
                && (this.name                 == o.name)
                && (this.label                == o.label)
                && (this.description          == o.description)
                && (this.options              == o.options)
        }
    }

    //////////////////
    // Representation for the database table: groupmembers
    //////////////////
    export class Groupmembers{
        create_time          : Date = new Date(0);
        id                   : number = 0;
        group_id             : number = 0;
        user_id              : number = 0;

        // Copy cTor
        constructor(o?:Groupmembers) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Groupmembers) {
            this.group_id             = o.group_id;
            this.user_id              = o.user_id;
        }

        // Content compare
        sameContent(o:Groupmembers) {
            return (this.group_id             == o.group_id)
                && (this.user_id              == o.user_id)
        }
    }

    //////////////////
    // Representation for the database table: logonsession
    //////////////////
    export class Logonsession{
        logon_time           : Date = new Date(0);
        logoff_time          : Date = new Date(0);
        update_time          : Date = new Date(0);
        is_active            : boolean = false;
        id                   : number = 0;
        user_id              : number = 0;
        logon_from           : string = "";

        // Copy cTor
        constructor(o?:Logonsession) {
            if (o) {
                this.setContent(o);    // set content fields
                this.update_time          = o.update_time;
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Logonsession) {
            this.logon_time           = o.logon_time;
            this.logoff_time          = o.logoff_time;
            this.is_active            = o.is_active;
            this.user_id              = o.user_id;
            this.logon_from           = o.logon_from;
        }

        // Content compare
        sameContent(o:Logonsession) {
            return (this.logon_time           == o.logon_time)
                && (this.logoff_time          == o.logoff_time)
                && (this.is_active            == o.is_active)
                && (this.user_id              == o.user_id)
                && (this.logon_from           == o.logon_from)
        }
    }

    //////////////////
    // Representation for the database table: meeting
    //////////////////
    export class Meeting{
        create_time          : Date = new Date(0);
        update_time          : Date = new Date(0);
        start_time           : Date = new Date(0);
        end_time             : Date = new Date(0);
        is_cancelled         : boolean = false;
        hide_participants    : boolean = false;
        id                   : number = 0;
        creator_id           : number = 0;
        updater_id           : number = 0;
        type_id              : number = 0;
        recurrence_id        : number = 0;
        monthly_topic_id     : number = 0;
        max_amigo_cnt        : number = 0;
        max_child_cnt        : number = 0;
        title                : string = "";
        description          : string = "";

        // Copy cTor
        constructor(o?:Meeting) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.update_time          = o.update_time;
                this.id                   = o.id;
                this.creator_id           = o.creator_id;
                this.updater_id           = o.updater_id;
            }
        }

        // Content assigner
        setContent(o:Meeting) {
            this.start_time           = o.start_time;
            this.end_time             = o.end_time;
            this.is_cancelled         = o.is_cancelled;
            this.hide_participants    = o.hide_participants;
            this.type_id              = o.type_id;
            this.recurrence_id        = o.recurrence_id;
            this.monthly_topic_id     = o.monthly_topic_id;
            this.max_amigo_cnt        = o.max_amigo_cnt;
            this.max_child_cnt        = o.max_child_cnt;
            this.title                = o.title;
            this.description          = o.description;
        }

        // Content compare
        sameContent(o:Meeting) {
            return (this.start_time           == o.start_time)
                && (this.end_time             == o.end_time)
                && (this.is_cancelled         == o.is_cancelled)
                && (this.hide_participants    == o.hide_participants)
                && (this.type_id              == o.type_id)
                && (this.recurrence_id        == o.recurrence_id)
                && (this.monthly_topic_id     == o.monthly_topic_id)
                && (this.max_amigo_cnt        == o.max_amigo_cnt)
                && (this.max_child_cnt        == o.max_child_cnt)
                && (this.title                == o.title)
                && (this.description          == o.description)
        }
    }

    //////////////////
    // Representation for the database table: meetinginvitee
    //////////////////
    export class Meetinginvitee{
        is_deleted           : boolean = false;
        is_member_group      : boolean = false;
        id                   : number = 0;
        meeting_id           : number = 0;
        member_id            : number = 0;

        // Copy cTor
        constructor(o?:Meetinginvitee) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Meetinginvitee) {
            this.is_deleted           = o.is_deleted;
            this.is_member_group      = o.is_member_group;
            this.meeting_id           = o.meeting_id;
            this.member_id            = o.member_id;
        }

        // Content compare
        sameContent(o:Meetinginvitee) {
            return (this.is_deleted           == o.is_deleted)
                && (this.is_member_group      == o.is_member_group)
                && (this.meeting_id           == o.meeting_id)
                && (this.member_id            == o.member_id)
        }
    }

    //////////////////
    // Representation for the database table: meetingparticipant
    //////////////////
    export class Meetingparticipant{
        attendance_update_time : Date = new Date(0);
        has_attended         : boolean = false;
        id                   : number = 0;
        meeting_id           : number = 0;
        user_id              : number = 0;
        state_id             : number = 0;
        attendance_updater_id : number = 0;
        attendance_comment   : string = "";

        // Copy cTor
        constructor(o?:Meetingparticipant) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Meetingparticipant) {
            this.attendance_update_time = o.attendance_update_time;
            this.has_attended         = o.has_attended;
            this.meeting_id           = o.meeting_id;
            this.user_id              = o.user_id;
            this.state_id             = o.state_id;
            this.attendance_updater_id = o.attendance_updater_id;
            this.attendance_comment   = o.attendance_comment;
        }

        // Content compare
        sameContent(o:Meetingparticipant) {
            return (this.attendance_update_time == o.attendance_update_time)
                && (this.has_attended         == o.has_attended)
                && (this.meeting_id           == o.meeting_id)
                && (this.user_id              == o.user_id)
                && (this.state_id             == o.state_id)
                && (this.attendance_updater_id == o.attendance_updater_id)
                && (this.attendance_comment   == o.attendance_comment)
        }
    }

    //////////////////
    // Representation for the database table: meetingrecurrence
    //////////////////
    export class Meetingrecurrence{
        id                   : number = 0;
        description          : string = "";
        code                 : string = "";

        // Copy cTor
        constructor(o?:Meetingrecurrence) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Meetingrecurrence) {
            this.description          = o.description;
            this.code                 = o.code;
        }

        // Content compare
        sameContent(o:Meetingrecurrence) {
            return (this.description          == o.description)
                && (this.code                 == o.code)
        }
    }

    //////////////////
    // Representation for the database table: meetingtype
    //////////////////
    export class Meetingtype{
        id                   : number = 0;
        description          : string = "";
        code                 : string = "";

        // Copy cTor
        constructor(o?:Meetingtype) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Meetingtype) {
            this.description          = o.description;
            this.code                 = o.code;
        }

        // Content compare
        sameContent(o:Meetingtype) {
            return (this.description          == o.description)
                && (this.code                 == o.code)
        }
    }

    //////////////////
    // Representation for the database table: message
    //////////////////
    export class Message{
        send_time            : Date = new Date(0);
        id                   : number = 0;
        type_id              : number = 0;
        sender_id            : number = 0;
        room_id              : number = 0;
        ref_id               : number = 0;
        attach_id            : number = 0;
        content              : string = "";

        // Copy cTor
        constructor(o?:Message) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Message) {
            this.send_time            = o.send_time;
            this.type_id              = o.type_id;
            this.sender_id            = o.sender_id;
            this.room_id              = o.room_id;
            this.ref_id               = o.ref_id;
            this.attach_id            = o.attach_id;
            this.content              = o.content;
        }

        // Content compare
        sameContent(o:Message) {
            return (this.send_time            == o.send_time)
                && (this.type_id              == o.type_id)
                && (this.sender_id            == o.sender_id)
                && (this.room_id              == o.room_id)
                && (this.ref_id               == o.ref_id)
                && (this.attach_id            == o.attach_id)
                && (this.content              == o.content)
        }
    }

    //////////////////
    // Representation for the database table: messagedelivery
    //////////////////
    export class Messagedelivery{
        delivery_time        : Date = new Date(0);
        id                   : number = 0;
        message_id           : number = 0;
        recipient_id         : number = 0;

        // Copy cTor
        constructor(o?:Messagedelivery) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Messagedelivery) {
            this.delivery_time        = o.delivery_time;
            this.message_id           = o.message_id;
            this.recipient_id         = o.recipient_id;
        }

        // Content compare
        sameContent(o:Messagedelivery) {
            return (this.delivery_time        == o.delivery_time)
                && (this.message_id           == o.message_id)
                && (this.recipient_id         == o.recipient_id)
        }
    }

    //////////////////
    // Representation for the database table: messagetype
    //////////////////
    export class Messagetype{
        id                   : number = 0;
        description          : string = "";
        code                 : string = "";

        // Copy cTor
        constructor(o?:Messagetype) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Messagetype) {
            this.description          = o.description;
            this.code                 = o.code;
        }

        // Content compare
        sameContent(o:Messagetype) {
            return (this.description          == o.description)
                && (this.code                 == o.code)
        }
    }

    //////////////////
    // Representation for the database table: monthlytopic
    //////////////////
    export class Monthlytopic{
        create_time          : Date = new Date(0);
        update_time          : Date = new Date(0);
        start_time           : Date = new Date(0);
        end_time             : Date = new Date(0);
        is_active            : boolean = false;
        id                   : number = 0;
        creator_id           : number = 0;
        updater_id           : number = 0;
        kid_fill_form        : number = 0;
        amigo_fill_form      : number = 0;
        title                : string = "";
        description          : string = "";

        // Copy cTor
        constructor(o?:Monthlytopic) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.update_time          = o.update_time;
                this.id                   = o.id;
                this.creator_id           = o.creator_id;
                this.updater_id           = o.updater_id;
            }
        }

        // Content assigner
        setContent(o:Monthlytopic) {
            this.start_time           = o.start_time;
            this.end_time             = o.end_time;
            this.is_active            = o.is_active;
            this.kid_fill_form        = o.kid_fill_form;
            this.amigo_fill_form      = o.amigo_fill_form;
            this.title                = o.title;
            this.description          = o.description;
        }

        // Content compare
        sameContent(o:Monthlytopic) {
            return (this.start_time           == o.start_time)
                && (this.end_time             == o.end_time)
                && (this.is_active            == o.is_active)
                && (this.kid_fill_form        == o.kid_fill_form)
                && (this.amigo_fill_form      == o.amigo_fill_form)
                && (this.title                == o.title)
                && (this.description          == o.description)
        }
    }

    //////////////////
    // Representation for the database table: participationstate
    //////////////////
    export class Participationstate{
        id                   : number = 0;
        description          : string = "";
        code                 : string = "";

        // Copy cTor
        constructor(o?:Participationstate) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Participationstate) {
            this.description          = o.description;
            this.code                 = o.code;
        }

        // Content compare
        sameContent(o:Participationstate) {
            return (this.description          == o.description)
                && (this.code                 == o.code)
        }
    }

    //////////////////
    // Representation for the database table: task
    //////////////////
    export class Task{
        create_time          : Date = new Date(0);
        update_time          : Date = new Date(0);
        dead_line            : Date = new Date(0);
        for_kids             : boolean = false;
        pre_meeting_task     : boolean = false;
        is_active            : boolean = false;
        is_cancelled         : boolean = false;
        id                   : number = 0;
        type_id              : number = 0;
        creator_id           : number = 0;
        updater_id           : number = 0;
        meeting_link         : number = 0;
        title                : string = "";
        reply_options        : string = "";
        description          : string = "";

        // Copy cTor
        constructor(o?:Task) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.update_time          = o.update_time;
                this.id                   = o.id;
                this.creator_id           = o.creator_id;
                this.updater_id           = o.updater_id;
            }
        }

        // Content assigner
        setContent(o:Task) {
            this.dead_line            = o.dead_line;
            this.for_kids             = o.for_kids;
            this.pre_meeting_task     = o.pre_meeting_task;
            this.is_active            = o.is_active;
            this.is_cancelled         = o.is_cancelled;
            this.type_id              = o.type_id;
            this.meeting_link         = o.meeting_link;
            this.title                = o.title;
            this.reply_options        = o.reply_options;
            this.description          = o.description;
        }

        // Content compare
        sameContent(o:Task) {
            return (this.dead_line            == o.dead_line)
                && (this.for_kids             == o.for_kids)
                && (this.pre_meeting_task     == o.pre_meeting_task)
                && (this.is_active            == o.is_active)
                && (this.is_cancelled         == o.is_cancelled)
                && (this.type_id              == o.type_id)
                && (this.meeting_link         == o.meeting_link)
                && (this.title                == o.title)
                && (this.reply_options        == o.reply_options)
                && (this.description          == o.description)
        }
    }

    //////////////////
    // Representation for the database table: taskassignee
    //////////////////
    export class Taskassignee{
        is_deleted           : boolean = false;
        is_member_group      : boolean = false;
        id                   : number = 0;
        task_id              : number = 0;
        member_id            : number = 0;

        // Copy cTor
        constructor(o?:Taskassignee) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Taskassignee) {
            this.is_deleted           = o.is_deleted;
            this.is_member_group      = o.is_member_group;
            this.task_id              = o.task_id;
            this.member_id            = o.member_id;
        }

        // Content compare
        sameContent(o:Taskassignee) {
            return (this.is_deleted           == o.is_deleted)
                && (this.is_member_group      == o.is_member_group)
                && (this.task_id              == o.task_id)
                && (this.member_id            == o.member_id)
        }
    }

    //////////////////
    // Representation for the database table: taskreply
    //////////////////
    export class Taskreply{
        create_time          : Date = new Date(0);
        reply_time           : Date = new Date(0);
        id                   : number = 0;
        task_id              : number = 0;
        user_id              : number = 0;
        reply                : string = "";

        // Copy cTor
        constructor(o?:Taskreply) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Taskreply) {
            this.reply_time           = o.reply_time;
            this.task_id              = o.task_id;
            this.user_id              = o.user_id;
            this.reply                = o.reply;
        }

        // Content compare
        sameContent(o:Taskreply) {
            return (this.reply_time           == o.reply_time)
                && (this.task_id              == o.task_id)
                && (this.user_id              == o.user_id)
                && (this.reply                == o.reply)
        }
    }

    //////////////////
    // Representation for the database table: tasktype
    //////////////////
    export class Tasktype{
        id                   : number = 0;
        description          : string = "";
        code                 : string = "";

        // Copy cTor
        constructor(o?:Tasktype) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Tasktype) {
            this.description          = o.description;
            this.code                 = o.code;
        }

        // Content compare
        sameContent(o:Tasktype) {
            return (this.description          == o.description)
                && (this.code                 == o.code)
        }
    }

    //////////////////
    // Representation for the database table: usergroup
    //////////////////
    export class Usergroup{
        create_time          : Date = new Date(0);
        update_time          : Date = new Date(0);
        is_public            : boolean = false;
        is_amigo_only        : boolean = false;
        is_active            : boolean = false;
        is_dynamic           : boolean = false;
        id                   : number = 0;
        creator_id           : number = 0;
        updater_id           : number = 0;
        name                 : string = "";
        dynamic_condition    : string = "";

        // Copy cTor
        constructor(o?:Usergroup) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.update_time          = o.update_time;
                this.id                   = o.id;
                this.creator_id           = o.creator_id;
                this.updater_id           = o.updater_id;
            }
        }

        // Content assigner
        setContent(o:Usergroup) {
            this.is_public            = o.is_public;
            this.is_amigo_only        = o.is_amigo_only;
            this.is_active            = o.is_active;
            this.is_dynamic           = o.is_dynamic;
            this.name                 = o.name;
            this.dynamic_condition    = o.dynamic_condition;
        }

        // Content compare
        sameContent(o:Usergroup) {
            return (this.is_public            == o.is_public)
                && (this.is_amigo_only        == o.is_amigo_only)
                && (this.is_active            == o.is_active)
                && (this.is_dynamic           == o.is_dynamic)
                && (this.name                 == o.name)
                && (this.dynamic_condition    == o.dynamic_condition)
        }
    }

    //////////////////
    // Representation for the database table: userpushid
    //////////////////
    export class Userpushid{
        created_time         : Date = new Date(0);
        update_time          : Date = new Date(0);
        is_deleted           : boolean = false;
        id                   : number = 0;
        user_id              : number = 0;
        push_uuid            : string = "";

        // Copy cTor
        constructor(o?:Userpushid) {
            if (o) {
                this.setContent(o);    // set content fields
                this.update_time          = o.update_time;
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Userpushid) {
            this.created_time         = o.created_time;
            this.is_deleted           = o.is_deleted;
            this.user_id              = o.user_id;
            this.push_uuid            = o.push_uuid;
        }

        // Content compare
        sameContent(o:Userpushid) {
            return (this.created_time         == o.created_time)
                && (this.is_deleted           == o.is_deleted)
                && (this.user_id              == o.user_id)
                && (this.push_uuid            == o.push_uuid)
        }
    }

    //////////////////
    // Representation for the database table: assigned_amigos_view
    //////////////////
    export class Assigned_amigos_view{
        is_active            : boolean = false;
        is_amigo             : boolean = false;
        is_admin             : boolean = false;
        disabled_amigo       : boolean = false;
        kid_id               : number = 0;
        amigo_id             : number = 0;
        login                : string = "";
        name                 : string = "";

        // Copy cTor
        constructor(o?:Assigned_amigos_view) {
            if (o) {
                this.setContent(o);    // set content fields
            }
        }

        // Content assigner
        setContent(o:Assigned_amigos_view) {
            this.is_active            = o.is_active;
            this.is_amigo             = o.is_amigo;
            this.is_admin             = o.is_admin;
            this.disabled_amigo       = o.disabled_amigo;
            this.kid_id               = o.kid_id;
            this.amigo_id             = o.amigo_id;
            this.login                = o.login;
            this.name                 = o.name;
        }

        // Content compare
        sameContent(o:Assigned_amigos_view) {
            return (this.is_active            == o.is_active)
                && (this.is_amigo             == o.is_amigo)
                && (this.is_admin             == o.is_admin)
                && (this.disabled_amigo       == o.disabled_amigo)
                && (this.kid_id               == o.kid_id)
                && (this.amigo_id             == o.amigo_id)
                && (this.login                == o.login)
                && (this.name                 == o.name)
        }
    }

    //////////////////
    // Representation for the database table: assigned_kids_view
    //////////////////
    export class Assigned_kids_view{
        is_active            : boolean = false;
        is_amigo             : boolean = false;
        is_admin             : boolean = false;
        disabled_kid         : boolean = false;
        kid_id               : number = 0;
        amigo_id             : number = 0;
        login                : string = "";
        name                 : string = "";

        // Copy cTor
        constructor(o?:Assigned_kids_view) {
            if (o) {
                this.setContent(o);    // set content fields
            }
        }

        // Content assigner
        setContent(o:Assigned_kids_view) {
            this.is_active            = o.is_active;
            this.is_amigo             = o.is_amigo;
            this.is_admin             = o.is_admin;
            this.disabled_kid         = o.disabled_kid;
            this.kid_id               = o.kid_id;
            this.amigo_id             = o.amigo_id;
            this.login                = o.login;
            this.name                 = o.name;
        }

        // Content compare
        sameContent(o:Assigned_kids_view) {
            return (this.is_active            == o.is_active)
                && (this.is_amigo             == o.is_amigo)
                && (this.is_admin             == o.is_admin)
                && (this.disabled_kid         == o.disabled_kid)
                && (this.kid_id               == o.kid_id)
                && (this.amigo_id             == o.amigo_id)
                && (this.login                == o.login)
                && (this.name                 == o.name)
        }
    }

    //////////////////
    // Representation for the database table: group_members_view
    //////////////////
    export class Group_members_view{
        create_time          : Date = new Date(0);
        update_time          : Date = new Date(0);
        member_since         : Date = new Date(0);
        is_amigo_only        : boolean = false;
        is_active            : boolean = false;
        is_public            : boolean = false;
        is_dynamic           : boolean = false;
        id                   : number = 0;
        creator_id           : number = 0;
        updater_id           : number = 0;
        user_id              : number = 0;
        name                 : string = "";
        dynamic_condition    : string = "";

        // Copy cTor
        constructor(o?:Group_members_view) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.update_time          = o.update_time;
                this.id                   = o.id;
                this.creator_id           = o.creator_id;
                this.updater_id           = o.updater_id;
            }
        }

        // Content assigner
        setContent(o:Group_members_view) {
            this.member_since         = o.member_since;
            this.is_amigo_only        = o.is_amigo_only;
            this.is_active            = o.is_active;
            this.is_public            = o.is_public;
            this.is_dynamic           = o.is_dynamic;
            this.user_id              = o.user_id;
            this.name                 = o.name;
            this.dynamic_condition    = o.dynamic_condition;
        }

        // Content compare
        sameContent(o:Group_members_view) {
            return (this.member_since         == o.member_since)
                && (this.is_amigo_only        == o.is_amigo_only)
                && (this.is_active            == o.is_active)
                && (this.is_public            == o.is_public)
                && (this.is_dynamic           == o.is_dynamic)
                && (this.user_id              == o.user_id)
                && (this.name                 == o.name)
                && (this.dynamic_condition    == o.dynamic_condition)
        }
    }

    //////////////////
    // Representation for the database table: group_users_view
    //////////////////
    export class Group_users_view{
        create_time          : Date = new Date(0);
        update_time          : Date = new Date(0);
        date_of_birth        : Date = new Date(0);
        is_amigo             : boolean = false;
        is_admin             : boolean = false;
        is_disabled          : boolean = false;
        is_kid               : boolean = false;
        public_group         : boolean = false;
        id                   : number = 0;
        avatar_doc_id        : number = 0;
        group_id             : number = 0;
        login                : string = "";
        password             : string = "";
        name                 : string = "";
        settings_json        : string = "";
        given_name           : string = "";
        family_name          : string = "";
        phone                : string = "";
        email                : string = "";
        questioner_json      : string = "";

        // Copy cTor
        constructor(o?:Group_users_view) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.update_time          = o.update_time;
                this.id                   = o.id;
                this.password             = o.password;
            }
        }

        // Content assigner
        setContent(o:Group_users_view) {
            this.date_of_birth        = o.date_of_birth;
            this.is_amigo             = o.is_amigo;
            this.is_admin             = o.is_admin;
            this.is_disabled          = o.is_disabled;
            this.is_kid               = o.is_kid;
            this.public_group         = o.public_group;
            this.avatar_doc_id        = o.avatar_doc_id;
            this.group_id             = o.group_id;
            this.login                = o.login;
            this.name                 = o.name;
            this.settings_json        = o.settings_json;
            this.given_name           = o.given_name;
            this.family_name          = o.family_name;
            this.phone                = o.phone;
            this.email                = o.email;
            this.questioner_json      = o.questioner_json;
        }

        // Content compare
        sameContent(o:Group_users_view) {
            return (this.date_of_birth        == o.date_of_birth)
                && (this.is_amigo             == o.is_amigo)
                && (this.is_admin             == o.is_admin)
                && (this.is_disabled          == o.is_disabled)
                && (this.is_kid               == o.is_kid)
                && (this.public_group         == o.public_group)
                && (this.avatar_doc_id        == o.avatar_doc_id)
                && (this.group_id             == o.group_id)
                && (this.login                == o.login)
                && (this.name                 == o.name)
                && (this.settings_json        == o.settings_json)
                && (this.given_name           == o.given_name)
                && (this.family_name          == o.family_name)
                && (this.phone                == o.phone)
                && (this.email                == o.email)
                && (this.questioner_json      == o.questioner_json)
        }
    }

    //////////////////
    // Representation for the database table: logon_session_view
    //////////////////
    export class Logon_session_view{
        logon_time           : Date = new Date(0);
        logoff_time          : Date = new Date(0);
        update_time          : Date = new Date(0);
        is_active            : boolean = false;
        is_amigo             : boolean = false;
        is_admin             : boolean = false;
        is_disabled          : boolean = false;
        is_kid               : boolean = false;
        id                   : number = 0;
        user_id              : number = 0;
        logon_from           : string = "";

        // Copy cTor
        constructor(o?:Logon_session_view) {
            if (o) {
                this.setContent(o);    // set content fields
                this.update_time          = o.update_time;
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Logon_session_view) {
            this.logon_time           = o.logon_time;
            this.logoff_time          = o.logoff_time;
            this.is_active            = o.is_active;
            this.is_amigo             = o.is_amigo;
            this.is_admin             = o.is_admin;
            this.is_disabled          = o.is_disabled;
            this.is_kid               = o.is_kid;
            this.user_id              = o.user_id;
            this.logon_from           = o.logon_from;
        }

        // Content compare
        sameContent(o:Logon_session_view) {
            return (this.logon_time           == o.logon_time)
                && (this.logoff_time          == o.logoff_time)
                && (this.is_active            == o.is_active)
                && (this.is_amigo             == o.is_amigo)
                && (this.is_admin             == o.is_admin)
                && (this.is_disabled          == o.is_disabled)
                && (this.is_kid               == o.is_kid)
                && (this.user_id              == o.user_id)
                && (this.logon_from           == o.logon_from)
        }
    }

    //////////////////
    // Representation for the database table: meeting_documents_view
    //////////////////
    export class Meeting_documents_view{
        upload_time          : Date = new Date(0);
        is_deleted           : boolean = false;
        id                   : number = 0;
        ref_id               : number = 0;
        meeting_id           : number = 0;
        uploader_id          : number = 0;
        type_id              : number = 0;
        file_size            : number = 0;
        type_code            : string = "";
        file_path            : string = "";
        description          : string = "";

        // Copy cTor
        constructor(o?:Meeting_documents_view) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Meeting_documents_view) {
            this.upload_time          = o.upload_time;
            this.is_deleted           = o.is_deleted;
            this.ref_id               = o.ref_id;
            this.meeting_id           = o.meeting_id;
            this.uploader_id          = o.uploader_id;
            this.type_id              = o.type_id;
            this.file_size            = o.file_size;
            this.type_code            = o.type_code;
            this.file_path            = o.file_path;
            this.description          = o.description;
        }

        // Content compare
        sameContent(o:Meeting_documents_view) {
            return (this.upload_time          == o.upload_time)
                && (this.is_deleted           == o.is_deleted)
                && (this.ref_id               == o.ref_id)
                && (this.meeting_id           == o.meeting_id)
                && (this.uploader_id          == o.uploader_id)
                && (this.type_id              == o.type_id)
                && (this.file_size            == o.file_size)
                && (this.type_code            == o.type_code)
                && (this.file_path            == o.file_path)
                && (this.description          == o.description)
        }
    }

    //////////////////
    // Representation for the database table: meeting_invitees_view
    //////////////////
    export class Meeting_invitees_view{
        is_member_group      : boolean = false;
        is_admin             : boolean = false;
        is_kid               : boolean = false;
        is_amigo             : boolean = false;
        member_id            : number = 0;
        meeting_id           : number = 0;

        // Copy cTor
        constructor(o?:Meeting_invitees_view) {
            if (o) {
                this.setContent(o);    // set content fields
            }
        }

        // Content assigner
        setContent(o:Meeting_invitees_view) {
            this.is_member_group      = o.is_member_group;
            this.is_admin             = o.is_admin;
            this.is_kid               = o.is_kid;
            this.is_amigo             = o.is_amigo;
            this.member_id            = o.member_id;
            this.meeting_id           = o.meeting_id;
        }

        // Content compare
        sameContent(o:Meeting_invitees_view) {
            return (this.is_member_group      == o.is_member_group)
                && (this.is_admin             == o.is_admin)
                && (this.is_kid               == o.is_kid)
                && (this.is_amigo             == o.is_amigo)
                && (this.member_id            == o.member_id)
                && (this.meeting_id           == o.meeting_id)
        }
    }

    //////////////////
    // Representation for the database table: meeting_members_view
    //////////////////
    export class Meeting_members_view{
        create_time          : Date = new Date(0);
        update_time          : Date = new Date(0);
        start_time           : Date = new Date(0);
        end_time             : Date = new Date(0);
        hide_participants    : boolean = false;
        user_id              : number = 0;
        id                   : number = 0;
        monthly_topic_id     : number = 0;
        creator_id           : number = 0;
        updater_id           : number = 0;
        type_id              : number = 0;
        recurrence_id        : number = 0;
        max_amigo_cnt        : number = 0;
        max_child_cnt        : number = 0;
        title                : string = "";
        description          : string = "";

        // Copy cTor
        constructor(o?:Meeting_members_view) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.update_time          = o.update_time;
                this.id                   = o.id;
                this.creator_id           = o.creator_id;
                this.updater_id           = o.updater_id;
            }
        }

        // Content assigner
        setContent(o:Meeting_members_view) {
            this.start_time           = o.start_time;
            this.end_time             = o.end_time;
            this.hide_participants    = o.hide_participants;
            this.user_id              = o.user_id;
            this.monthly_topic_id     = o.monthly_topic_id;
            this.type_id              = o.type_id;
            this.recurrence_id        = o.recurrence_id;
            this.max_amigo_cnt        = o.max_amigo_cnt;
            this.max_child_cnt        = o.max_child_cnt;
            this.title                = o.title;
            this.description          = o.description;
        }

        // Content compare
        sameContent(o:Meeting_members_view) {
            return (this.start_time           == o.start_time)
                && (this.end_time             == o.end_time)
                && (this.hide_participants    == o.hide_participants)
                && (this.user_id              == o.user_id)
                && (this.monthly_topic_id     == o.monthly_topic_id)
                && (this.type_id              == o.type_id)
                && (this.recurrence_id        == o.recurrence_id)
                && (this.max_amigo_cnt        == o.max_amigo_cnt)
                && (this.max_child_cnt        == o.max_child_cnt)
                && (this.title                == o.title)
                && (this.description          == o.description)
        }
    }

    //////////////////
    // Representation for the database table: meeting_participants_view
    //////////////////
    export class Meeting_participants_view{
        is_admin             : boolean = false;
        is_kid               : boolean = false;
        is_amigo             : boolean = false;
        user_id              : number = 0;
        meeting_id           : number = 0;

        // Copy cTor
        constructor(o?:Meeting_participants_view) {
            if (o) {
                this.setContent(o);    // set content fields
            }
        }

        // Content assigner
        setContent(o:Meeting_participants_view) {
            this.is_admin             = o.is_admin;
            this.is_kid               = o.is_kid;
            this.is_amigo             = o.is_amigo;
            this.user_id              = o.user_id;
            this.meeting_id           = o.meeting_id;
        }

        // Content compare
        sameContent(o:Meeting_participants_view) {
            return (this.is_admin             == o.is_admin)
                && (this.is_kid               == o.is_kid)
                && (this.is_amigo             == o.is_amigo)
                && (this.user_id              == o.user_id)
                && (this.meeting_id           == o.meeting_id)
        }
    }

    //////////////////
    // Representation for the database table: meeting_schedule_view
    //////////////////
    export class Meeting_schedule_view{
        start_time           : Date = new Date(0);
        end_time             : Date = new Date(0);
        is_member_group      : boolean = false;
        id                   : number = 0;
        invitee_id           : number = 0;
        user_id              : number = 0;
        participant_id       : number = 0;
        monthly_topic_id     : number = 0;
        type_id              : number = 0;
        recurrence_id        : number = 0;
        starts               : number = 0;
        ended                : number = 0;
        title                : string = "";

        // Copy cTor
        constructor(o?:Meeting_schedule_view) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Meeting_schedule_view) {
            this.start_time           = o.start_time;
            this.end_time             = o.end_time;
            this.is_member_group      = o.is_member_group;
            this.invitee_id           = o.invitee_id;
            this.user_id              = o.user_id;
            this.participant_id       = o.participant_id;
            this.monthly_topic_id     = o.monthly_topic_id;
            this.type_id              = o.type_id;
            this.recurrence_id        = o.recurrence_id;
            this.starts               = o.starts;
            this.ended                = o.ended;
            this.title                = o.title;
        }

        // Content compare
        sameContent(o:Meeting_schedule_view) {
            return (this.start_time           == o.start_time)
                && (this.end_time             == o.end_time)
                && (this.is_member_group      == o.is_member_group)
                && (this.invitee_id           == o.invitee_id)
                && (this.user_id              == o.user_id)
                && (this.participant_id       == o.participant_id)
                && (this.monthly_topic_id     == o.monthly_topic_id)
                && (this.type_id              == o.type_id)
                && (this.recurrence_id        == o.recurrence_id)
                && (this.starts               == o.starts)
                && (this.ended                == o.ended)
                && (this.title                == o.title)
        }
    }

    //////////////////
    // Representation for the database table: meeting_tasks_view
    //////////////////
    export class Meeting_tasks_view{
        start_time           : Date = new Date(0);
        end_time             : Date = new Date(0);
        task_deadline        : Date = new Date(0);
        topic_start_time     : Date = new Date(0);
        topic_end_time       : Date = new Date(0);
        task_kids            : boolean = false;
        pre_meeting_task     : boolean = false;
        task_active          : boolean = false;
        task_cancelled       : boolean = false;
        topic_active         : boolean = false;
        id                   : number = 0;
        starts               : number = 0;
        ended                : number = 0;
        task_id              : number = 0;
        task_type            : number = 0;
        topic_id             : number = 0;
        topic_kid_form_id    : number = 0;
        topic_amigo_form_id  : number = 0;
        task_title           : string = "";
        task_options         : string = "";
        topic_title          : string = "";

        // Copy cTor
        constructor(o?:Meeting_tasks_view) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Meeting_tasks_view) {
            this.start_time           = o.start_time;
            this.end_time             = o.end_time;
            this.task_deadline        = o.task_deadline;
            this.topic_start_time     = o.topic_start_time;
            this.topic_end_time       = o.topic_end_time;
            this.task_kids            = o.task_kids;
            this.pre_meeting_task     = o.pre_meeting_task;
            this.task_active          = o.task_active;
            this.task_cancelled       = o.task_cancelled;
            this.topic_active         = o.topic_active;
            this.starts               = o.starts;
            this.ended                = o.ended;
            this.task_id              = o.task_id;
            this.task_type            = o.task_type;
            this.topic_id             = o.topic_id;
            this.topic_kid_form_id    = o.topic_kid_form_id;
            this.topic_amigo_form_id  = o.topic_amigo_form_id;
            this.task_title           = o.task_title;
            this.task_options         = o.task_options;
            this.topic_title          = o.topic_title;
        }

        // Content compare
        sameContent(o:Meeting_tasks_view) {
            return (this.start_time           == o.start_time)
                && (this.end_time             == o.end_time)
                && (this.task_deadline        == o.task_deadline)
                && (this.topic_start_time     == o.topic_start_time)
                && (this.topic_end_time       == o.topic_end_time)
                && (this.task_kids            == o.task_kids)
                && (this.pre_meeting_task     == o.pre_meeting_task)
                && (this.task_active          == o.task_active)
                && (this.task_cancelled       == o.task_cancelled)
                && (this.topic_active         == o.topic_active)
                && (this.starts               == o.starts)
                && (this.ended                == o.ended)
                && (this.task_id              == o.task_id)
                && (this.task_type            == o.task_type)
                && (this.topic_id             == o.topic_id)
                && (this.topic_kid_form_id    == o.topic_kid_form_id)
                && (this.topic_amigo_form_id  == o.topic_amigo_form_id)
                && (this.task_title           == o.task_title)
                && (this.task_options         == o.task_options)
                && (this.topic_title          == o.topic_title)
        }
    }

    //////////////////
    // Representation for the database table: messages_view
    //////////////////
    export class Messages_view{
        send_time            : Date = new Date(0);
        id                   : number = 0;
        type_id              : number = 0;
        sender_id            : number = 0;
        attach_id            : number = 0;
        ref_id               : number = 0;
        room_id              : number = 0;
        user_id              : number = 0;
        content              : string = "";

        // Copy cTor
        constructor(o?:Messages_view) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Messages_view) {
            this.send_time            = o.send_time;
            this.type_id              = o.type_id;
            this.sender_id            = o.sender_id;
            this.attach_id            = o.attach_id;
            this.ref_id               = o.ref_id;
            this.room_id              = o.room_id;
            this.user_id              = o.user_id;
            this.content              = o.content;
        }

        // Content compare
        sameContent(o:Messages_view) {
            return (this.send_time            == o.send_time)
                && (this.type_id              == o.type_id)
                && (this.sender_id            == o.sender_id)
                && (this.attach_id            == o.attach_id)
                && (this.ref_id               == o.ref_id)
                && (this.room_id              == o.room_id)
                && (this.user_id              == o.user_id)
                && (this.content              == o.content)
        }
    }

    //////////////////
    // Representation for the database table: mothly_topic_meeting_view
    //////////////////
    export class Mothly_topic_meeting_view{
        create_time          : Date = new Date(0);
        update_time          : Date = new Date(0);
        start_time           : Date = new Date(0);
        end_time             : Date = new Date(0);
        topic_start          : Date = new Date(0);
        topic_end            : Date = new Date(0);
        topic_is_active      : boolean = false;
        hide_participants    : boolean = false;
        id                   : number = 0;
        monthly_topic_id     : number = 0;
        creator_id           : number = 0;
        updater_id           : number = 0;
        type_id              : number = 0;
        recurrence_id        : number = 0;
        max_amigo_cnt        : number = 0;
        max_child_cnt        : number = 0;
        title                : string = "";
        description          : string = "";

        // Copy cTor
        constructor(o?:Mothly_topic_meeting_view) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.update_time          = o.update_time;
                this.id                   = o.id;
                this.creator_id           = o.creator_id;
                this.updater_id           = o.updater_id;
            }
        }

        // Content assigner
        setContent(o:Mothly_topic_meeting_view) {
            this.start_time           = o.start_time;
            this.end_time             = o.end_time;
            this.topic_start          = o.topic_start;
            this.topic_end            = o.topic_end;
            this.topic_is_active      = o.topic_is_active;
            this.hide_participants    = o.hide_participants;
            this.monthly_topic_id     = o.monthly_topic_id;
            this.type_id              = o.type_id;
            this.recurrence_id        = o.recurrence_id;
            this.max_amigo_cnt        = o.max_amigo_cnt;
            this.max_child_cnt        = o.max_child_cnt;
            this.title                = o.title;
            this.description          = o.description;
        }

        // Content compare
        sameContent(o:Mothly_topic_meeting_view) {
            return (this.start_time           == o.start_time)
                && (this.end_time             == o.end_time)
                && (this.topic_start          == o.topic_start)
                && (this.topic_end            == o.topic_end)
                && (this.topic_is_active      == o.topic_is_active)
                && (this.hide_participants    == o.hide_participants)
                && (this.monthly_topic_id     == o.monthly_topic_id)
                && (this.type_id              == o.type_id)
                && (this.recurrence_id        == o.recurrence_id)
                && (this.max_amigo_cnt        == o.max_amigo_cnt)
                && (this.max_child_cnt        == o.max_child_cnt)
                && (this.title                == o.title)
                && (this.description          == o.description)
        }
    }

    //////////////////
    // Representation for the database table: room_members_view
    //////////////////
    export class Room_members_view{
        is_public            : boolean = false;
        is_personal          : boolean = false;
        is_amigo_only        : boolean = false;
        is_member_group      : boolean = false;
        unique_id            : string = "";
        title                : string = "";
        room_id              : number = 0;
        user_id              : number = 0;

        // Copy cTor
        constructor(o?:Room_members_view) {
            if (o) {
                this.setContent(o);    // set content fields
            }
        }

        // Content assigner
        setContent(o:Room_members_view) {
            this.is_public            = o.is_public;
            this.is_personal          = o.is_personal;
            this.is_amigo_only        = o.is_amigo_only;
            this.is_member_group      = o.is_member_group;
            this.unique_id            = o.unique_id;
            this.title                = o.title;
            this.room_id              = o.room_id;
            this.user_id              = o.user_id;
        }

        // Content compare
        sameContent(o:Room_members_view) {
            return (this.is_public            == o.is_public)
                && (this.is_personal          == o.is_personal)
                && (this.is_amigo_only        == o.is_amigo_only)
                && (this.is_member_group      == o.is_member_group)
                && (this.unique_id            == o.unique_id)
                && (this.title                == o.title)
                && (this.room_id              == o.room_id)
                && (this.user_id              == o.user_id)
        }
    }

    //////////////////
    // Representation for the database table: room_users_view
    //////////////////
    export class Room_users_view{
        create_time          : Date = new Date(0);
        update_time          : Date = new Date(0);
        date_of_birth        : Date = new Date(0);
        is_amigo             : boolean = false;
        is_admin             : boolean = false;
        is_disabled          : boolean = false;
        is_kid               : boolean = false;
        is_active            : boolean = false;
        id                   : number = 0;
        avatar_doc_id        : number = 0;
        room_id              : number = 0;
        login                : string = "";
        password             : string = "";
        name                 : string = "";
        settings_json        : string = "";
        given_name           : string = "";
        family_name          : string = "";
        phone                : string = "";
        email                : string = "";
        questioner_json      : string = "";

        // Copy cTor
        constructor(o?:Room_users_view) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.update_time          = o.update_time;
                this.id                   = o.id;
                this.password             = o.password;
            }
        }

        // Content assigner
        setContent(o:Room_users_view) {
            this.date_of_birth        = o.date_of_birth;
            this.is_amigo             = o.is_amigo;
            this.is_admin             = o.is_admin;
            this.is_disabled          = o.is_disabled;
            this.is_kid               = o.is_kid;
            this.is_active            = o.is_active;
            this.avatar_doc_id        = o.avatar_doc_id;
            this.room_id              = o.room_id;
            this.login                = o.login;
            this.name                 = o.name;
            this.settings_json        = o.settings_json;
            this.given_name           = o.given_name;
            this.family_name          = o.family_name;
            this.phone                = o.phone;
            this.email                = o.email;
            this.questioner_json      = o.questioner_json;
        }

        // Content compare
        sameContent(o:Room_users_view) {
            return (this.date_of_birth        == o.date_of_birth)
                && (this.is_amigo             == o.is_amigo)
                && (this.is_admin             == o.is_admin)
                && (this.is_disabled          == o.is_disabled)
                && (this.is_kid               == o.is_kid)
                && (this.is_active            == o.is_active)
                && (this.avatar_doc_id        == o.avatar_doc_id)
                && (this.room_id              == o.room_id)
                && (this.login                == o.login)
                && (this.name                 == o.name)
                && (this.settings_json        == o.settings_json)
                && (this.given_name           == o.given_name)
                && (this.family_name          == o.family_name)
                && (this.phone                == o.phone)
                && (this.email                == o.email)
                && (this.questioner_json      == o.questioner_json)
        }
    }

    //////////////////
    // Representation for the database table: task_members_view
    //////////////////
    export class Task_members_view{
        create_time          : Date = new Date(0);
        update_time          : Date = new Date(0);
        dead_line            : Date = new Date(0);
        is_active            : boolean = false;
        for_kids             : boolean = false;
        is_cancelled         : boolean = false;
        user_id              : number = 0;
        id                   : number = 0;
        type_id              : number = 0;
        creator_id           : number = 0;
        updater_id           : number = 0;
        meeting_link         : number = 0;
        title                : string = "";
        reply_options        : string = "";
        description          : string = "";

        // Copy cTor
        constructor(o?:Task_members_view) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.update_time          = o.update_time;
                this.id                   = o.id;
                this.creator_id           = o.creator_id;
                this.updater_id           = o.updater_id;
            }
        }

        // Content assigner
        setContent(o:Task_members_view) {
            this.dead_line            = o.dead_line;
            this.is_active            = o.is_active;
            this.for_kids             = o.for_kids;
            this.is_cancelled         = o.is_cancelled;
            this.user_id              = o.user_id;
            this.type_id              = o.type_id;
            this.meeting_link         = o.meeting_link;
            this.title                = o.title;
            this.reply_options        = o.reply_options;
            this.description          = o.description;
        }

        // Content compare
        sameContent(o:Task_members_view) {
            return (this.dead_line            == o.dead_line)
                && (this.is_active            == o.is_active)
                && (this.for_kids             == o.for_kids)
                && (this.is_cancelled         == o.is_cancelled)
                && (this.user_id              == o.user_id)
                && (this.type_id              == o.type_id)
                && (this.meeting_link         == o.meeting_link)
                && (this.title                == o.title)
                && (this.reply_options        == o.reply_options)
                && (this.description          == o.description)
        }
    }

    //////////////////
    // Representation for the database table: task_todo_view
    //////////////////
    export class Task_todo_view{
        dead_line            : Date = new Date(0);
        is_member_group      : boolean = false;
        is_active            : boolean = false;
        for_kids             : boolean = false;
        is_cancelled         : boolean = false;
        assignee_id          : number = 0;
        user_id              : number = 0;
        id                   : number = 0;
        type_id              : number = 0;
        expires              : number = 0;
        meeting_link         : number = 0;
        reply_id             : number = 0;
        title                : string = "";

        // Copy cTor
        constructor(o?:Task_todo_view) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Task_todo_view) {
            this.dead_line            = o.dead_line;
            this.is_member_group      = o.is_member_group;
            this.is_active            = o.is_active;
            this.for_kids             = o.for_kids;
            this.is_cancelled         = o.is_cancelled;
            this.assignee_id          = o.assignee_id;
            this.user_id              = o.user_id;
            this.type_id              = o.type_id;
            this.expires              = o.expires;
            this.meeting_link         = o.meeting_link;
            this.reply_id             = o.reply_id;
            this.title                = o.title;
        }

        // Content compare
        sameContent(o:Task_todo_view) {
            return (this.dead_line            == o.dead_line)
                && (this.is_member_group      == o.is_member_group)
                && (this.is_active            == o.is_active)
                && (this.for_kids             == o.for_kids)
                && (this.is_cancelled         == o.is_cancelled)
                && (this.assignee_id          == o.assignee_id)
                && (this.user_id              == o.user_id)
                && (this.type_id              == o.type_id)
                && (this.expires              == o.expires)
                && (this.meeting_link         == o.meeting_link)
                && (this.reply_id             == o.reply_id)
                && (this.title                == o.title)
        }
    }

    //////////////////
    // Representation for the database table: taskreply_documents_view
    //////////////////
    export class Taskreply_documents_view{
        upload_time          : Date = new Date(0);
        is_deleted           : boolean = false;
        id                   : number = 0;
        ref_id               : number = 0;
        meeting_id           : number = 0;
        uploader_id          : number = 0;
        type_id              : number = 0;
        file_size            : number = 0;
        reply_id             : number = 0;
        task_id              : number = 0;
        user_id              : number = 0;
        type_code            : string = "";
        file_path            : string = "";
        description          : string = "";
        reply                : string = "";

        // Copy cTor
        constructor(o?:Taskreply_documents_view) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Taskreply_documents_view) {
            this.upload_time          = o.upload_time;
            this.is_deleted           = o.is_deleted;
            this.ref_id               = o.ref_id;
            this.meeting_id           = o.meeting_id;
            this.uploader_id          = o.uploader_id;
            this.type_id              = o.type_id;
            this.file_size            = o.file_size;
            this.reply_id             = o.reply_id;
            this.task_id              = o.task_id;
            this.user_id              = o.user_id;
            this.type_code            = o.type_code;
            this.file_path            = o.file_path;
            this.description          = o.description;
            this.reply                = o.reply;
        }

        // Content compare
        sameContent(o:Taskreply_documents_view) {
            return (this.upload_time          == o.upload_time)
                && (this.is_deleted           == o.is_deleted)
                && (this.ref_id               == o.ref_id)
                && (this.meeting_id           == o.meeting_id)
                && (this.uploader_id          == o.uploader_id)
                && (this.type_id              == o.type_id)
                && (this.file_size            == o.file_size)
                && (this.reply_id             == o.reply_id)
                && (this.task_id              == o.task_id)
                && (this.user_id              == o.user_id)
                && (this.type_code            == o.type_code)
                && (this.file_path            == o.file_path)
                && (this.description          == o.description)
                && (this.reply                == o.reply)
        }
    }

    //////////////////
    // Representation for the database table: taskreply_users_view
    //////////////////
    export class Taskreply_users_view{
        create_time          : Date = new Date(0);
        reply_time           : Date = new Date(0);
        is_amigo             : boolean = false;
        is_admin             : boolean = false;
        is_disabled          : boolean = false;
        is_kid               : boolean = false;
        id                   : number = 0;
        task_id              : number = 0;
        user_id              : number = 0;
        reply                : string = "";
        login                : string = "";
        name                 : string = "";
        email                : string = "";
        given_name           : string = "";
        family_name          : string = "";

        // Copy cTor
        constructor(o?:Taskreply_users_view) {
            if (o) {
                this.setContent(o);    // set content fields
                this.create_time          = o.create_time;
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Taskreply_users_view) {
            this.reply_time           = o.reply_time;
            this.is_amigo             = o.is_amigo;
            this.is_admin             = o.is_admin;
            this.is_disabled          = o.is_disabled;
            this.is_kid               = o.is_kid;
            this.task_id              = o.task_id;
            this.user_id              = o.user_id;
            this.reply                = o.reply;
            this.login                = o.login;
            this.name                 = o.name;
            this.email                = o.email;
            this.given_name           = o.given_name;
            this.family_name          = o.family_name;
        }

        // Content compare
        sameContent(o:Taskreply_users_view) {
            return (this.reply_time           == o.reply_time)
                && (this.is_amigo             == o.is_amigo)
                && (this.is_admin             == o.is_admin)
                && (this.is_disabled          == o.is_disabled)
                && (this.is_kid               == o.is_kid)
                && (this.task_id              == o.task_id)
                && (this.user_id              == o.user_id)
                && (this.reply                == o.reply)
                && (this.login                == o.login)
                && (this.name                 == o.name)
                && (this.email                == o.email)
                && (this.given_name           == o.given_name)
                && (this.family_name          == o.family_name)
        }
    }

    //////////////////
    // Representation for the database table: topic_documents_view
    //////////////////
    export class Topic_documents_view{
        upload_time          : Date = new Date(0);
        is_deleted           : boolean = false;
        id                   : number = 0;
        ref_id               : number = 0;
        topic_id             : number = 0;
        uploader_id          : number = 0;
        type_id              : number = 0;
        file_size            : number = 0;
        type_code            : string = "";
        file_path            : string = "";
        description          : string = "";

        // Copy cTor
        constructor(o?:Topic_documents_view) {
            if (o) {
                this.setContent(o);    // set content fields
                this.id                   = o.id;
            }
        }

        // Content assigner
        setContent(o:Topic_documents_view) {
            this.upload_time          = o.upload_time;
            this.is_deleted           = o.is_deleted;
            this.ref_id               = o.ref_id;
            this.topic_id             = o.topic_id;
            this.uploader_id          = o.uploader_id;
            this.type_id              = o.type_id;
            this.file_size            = o.file_size;
            this.type_code            = o.type_code;
            this.file_path            = o.file_path;
            this.description          = o.description;
        }

        // Content compare
        sameContent(o:Topic_documents_view) {
            return (this.upload_time          == o.upload_time)
                && (this.is_deleted           == o.is_deleted)
                && (this.ref_id               == o.ref_id)
                && (this.topic_id             == o.topic_id)
                && (this.uploader_id          == o.uploader_id)
                && (this.type_id              == o.type_id)
                && (this.file_size            == o.file_size)
                && (this.type_code            == o.type_code)
                && (this.file_path            == o.file_path)
                && (this.description          == o.description)
        }
    }
};

export default DB;