import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n'
import VueQuill from "vue-quill";
// const VueQuill = require('vue-quill');

Vue.config.productionTip = false

Vue.use(VueI18n)
Vue.use(VueQuill);

function loadLocaleMessages() : VueI18n.LocaleMessages {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: VueI18n.LocaleMessages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages;
}

const i18n = new VueI18n({
  locale: 'hu',
  messages: loadLocaleMessages()
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  i18n
}).$mount('#app')
