import Vue from 'vue'
import Vuex from 'vuex'
import { REST } from '../_util/rest_call';
import Net from '../../../common/net_interface'
import DB from '../../../common/db_struct';

var pendingQuestionerCalls = new Set<number>();
var pendingUserCalls = new Set<number>();
var pendingDocumentCalls = new Set<number>();
var pendingPairCalls = new Set<number>();
var pendingGroupCalls = new Set<number>();
var pendingTaskCalls = new Set<number>();
var pendingMeetingCalls = new Set<number>();
var pendingRoomCalls = new Set<number>();
var pendingRoomMessagesCalls = new Set<number>();
var ws : WebSocket | null;

Vue.use(Vuex)

export default new Vuex.Store({
  // strict: process.env.NODE_ENV !== 'production',
  state: {
    appTitle: "",
    selChatRoomId: -1,                                // ID of the currently selected chat room
    sideMenuOpen: true,
    wsConnected: false,                               // tracks whether WebSocket is connected
    nomenclator: new Net.Nomenclator(),               // Nomenclators
    documents: new Map<number, DB.Document>(),
    allQuestionerRequested: false,
    questioner: new Map<number, Net.FillOutForm>(),            // map userId to user data
    allUsersRequested: false,
    users: new Map<number, Net.Appuser>(),            // map userId to user data
    allPairingRequested: false,
    pairs: new Map<number, DB.Amigoskids>(),          // map Amigo/Kid pairs
    allRoomsRequested: false,
    rooms: new Map<number, Net.ChatRoomAndMembers>(), // map roomId to roomData including messages Ids
    messages: new Map<number, DB.Message>(),          // map messageId to message 
    allTasksRequested: false,
    tasks: new Map<number, Net.TaskAndAssignee>(), // map taskId to task details
    allMeetingsRequested: false,
    meetings: new Map<number, Net.MeetingAndInvitees>(),          // map calendarId to calendar details
    allTopicsRequested: false,
    topics: new Map<number, DB.Monthlytopic>(),       // map monthly topics
    allGroupsRequested: false,
    groups: new Map<number, Net.UserGroupAndMembers>()// map userGroupId to usergroup
  },
  getters: {
    appTitle:(state) : string => {
      return state.appTitle;
    },
    selChatRoomId:(state) : Number => {
      return state.selChatRoomId;
    },
    isWsConnected: (state) : boolean => {
      return state.wsConnected;
    },
    sideMenuOpen: (state) : boolean => {
      return state.sideMenuOpen;
    },
    getRoom: (state) =>( id:number) : Net.ChatRoomAndMembers | undefined => {
      return state.rooms.get(Number(id));
    },
    getRooms: (state) : Map<number, Net.ChatRoomAndMembers> => {
      return state.rooms;
    },
    getGroup: (state) => (id:number) : Net.UserGroupAndMembers | undefined => {
      return state.groups.get(Number(id));
    },
    getGroups: (state) : Map<number, Net.UserGroupAndMembers> => {
      return state.groups;
    },
    getQuestioners: (state) : Map<number, Net.FillOutForm> => {
      return state.questioner;
    },
    getQuestioner: (state) => (id:number) : Net.FillOutForm | undefined => {
      return state.questioner.get(Number(id));
    },
    getUsers: (state) : Map<number, Net.Appuser> => {
      return state.users;
    },
    getUser: (state) => (id:number) : Net.Appuser | undefined => {
      return state.users.get(Number(id));
    },
    getDocuments: (state) : Map<number, DB.Document> => {
      return state.documents;
    },
    getDocument: (state) => (id:number) : DB.Document | undefined => {
      return state.documents.get(Number(id));
    },
    getPairs: (state) : Map<number, DB.Amigoskids> => {
      return state.pairs;
    },
    getPair: (state) => (id:number) : DB.Amigoskids | undefined => {
      return state.pairs.get(Number(id));
    },
    getMessages: (state) : Map<number, DB.Message> => {
      return state.messages;
    },
    getTask: (state) =>( id:number) : Net.TaskAndAssignee | undefined => {
      return state.tasks.get(Number(id));
    },
    getTasks: (state) : Map<number, Net.TaskAndAssignee> => {
      return state.tasks;
    },
    getMeeting: (state) =>( id:number) : Net.MeetingAndInvitees | undefined => {
      return state.meetings.get(Number(id));
    },
    getMeetings: (state) : Map<number, Net.MeetingAndInvitees> => {
      return state.meetings;
    },
    getTopics: (state) : Map<number, DB.Monthlytopic> => {
      return state.topics;
    },
    getMeetingFromTo: (state) => (from:Date, to:Date) : Array<Net.MeetingAndInvitees> => {
      let rv : Array<Net.MeetingAndInvitees> = [];
      state.meetings.forEach((value) => {
        if ((value.end_time > from) || (value.start_time < to)) {
          rv.push(value);
        }
      })
      return rv
    },
    nomenclator: (state) : Net.Nomenclator => {
      return state.nomenclator;
    },
  },
  mutations: {
    clearReceivedAllFlags: (state) => {
      state.allQuestionerRequested = false;
      state.allUsersRequested = false;
      state.allPairingRequested = false;
      state.allRoomsRequested = false;
      state.allTasksRequested = false;
      state.allMeetingsRequested = false;
      state.allTopicsRequested = false;
      state.allGroupsRequested = false;
    },
    emptyAll: (state) => {
      state.sideMenuOpen = true;
      //? needed? state.nomenclator.clear();
      state.documents.clear();
      state.allQuestionerRequested = false;
      state.questioner.clear();
      state.allUsersRequested = false;
      state.users.clear();
      state.allPairingRequested = false;
      state.pairs.clear();
      state.allRoomsRequested = false;
      state.rooms.clear();
      state.messages.clear();
      state.allTasksRequested = false;
      state.tasks.clear();
      state.allMeetingsRequested = false;
      state.meetings.clear();
      state.allTopicsRequested = false;
      state.topics.clear();
      state.allGroupsRequested = false;
      state.groups.clear();
    },
    setAppTitle: (state, title:string) => {
      state.appTitle = title;
    },
    setSelChatRoomId: (state, id:number) => {
      state.selChatRoomId = id;
    },
    setSideMenuOpen: (state, open:boolean) => {
      state.sideMenuOpen = open;
    },
    setWsState: (state, isConnected:boolean) => {
      state.wsConnected = isConnected;
      if ((!state.wsConnected) && (ws != null)) {
        ws.close();
      }
    },
    setUserPresence: (state, v:Net.User_Presence) => {
      v.userPresence.forEach(idAndPresence => {
        let user = state.users.get(Number(idAndPresence[0]));
        if (!user) {
          user = new Net.Appuser(new DB.Appuser());
          user.id = idAndPresence[0];
        }
        (user as Net.Appuser).is_present = idAndPresence[1];
        state.users.set(Number(idAndPresence[0]), user);
      })
    },
    setDocument: (state, v:DB.Document) => {
      state.documents.set(Number(v.id), v);
    },
    mutateQuestionerArray: (state, questioners:Array<Net.FillOutForm>) => {
      questioners.forEach(v => {
        state.questioner.set(Number(v.id), v);
      });
    },
    setUser: (state, v:Net.Appuser) => {
      let user = state.users.get(Number(v.id));
      if (user) {
        v.is_present = (user as Net.Appuser).is_present;
      }
      state.users.set(Number(v.id), v);
    },
    mutateUserArray: (state, users:Array<Net.Appuser>) => {
      users.forEach(v => {
        let user = state.users.get(Number(v.id));
        if (user) {
          v.is_present = (user as Net.Appuser).is_present;
        }
        state.users.set(Number(v.id), v);
      });
    },
    setPair: (state, v:DB.Amigoskids) => {
      REST.logDebug("MUTATION: setPair", v)
      state.pairs.set(Number(v.id), v);
    },
    mutatePairArray: (state, pairs:Array<DB.Amigoskids>) => {
      REST.logDebug("MUTATION: mutatePairArray", pairs)
      pairs.forEach(v => {
        state.pairs.set(Number(v.id), v);
      });
    },
    mutateGroupArray: (state, groups:Array<Net.UserGroupAndMembers>) => {
      groups.forEach(v => {
        state.groups.set(Number(v.id), v);
      });
    },
    incUnreadMsgCount: (state, room_id:number) => {
      let room = state.rooms.get(Number(room_id));
      if (room) {
        room.unreadMsgCount += 1;
      }
    },
    clearUnreadMsgCount: (state, room_id:number) => {
      let room = state.rooms.get(Number(room_id));
      if (room) {
        room.unreadMsgCount = 0;
      }
    },
    roomAllMsgReceived: (state, payload:{ id:number, chunk:Array<DB.Message> }) => {
      let room = state.rooms.get(Number(payload.id));
      if (room) {
        room.allMsgReceived = payload.chunk.length < Net.CHAT_MSG_CHUNK_SIZE;
        if (payload.chunk.length > 0) {
          // NOTE: result is sorted by time in a descending order
          room.firstMessageId = payload.chunk[payload.chunk.length -1].id;
        }
      }
    },
    mutateRoomArray: (state, rooms:Array<Net.ChatRoomAndMembers>) => {
      rooms.forEach(v => {
        let prev = state.rooms.get(Number(v.id));
        if (prev) {
          v.firstMessageId = prev.firstMessageId;
          v.allMsgReceived = prev.allMsgReceived;
          v.unreadMsgCount = prev.unreadMsgCount;
        }
        state.rooms.set(Number(v.id), v);
      });
    },
    deleteRoom: (state, room_id:number) => {
      state.rooms.delete(Number(room_id));
    },
    setMessage: (state, message:DB.Message) => {
      state.messages.set(Number(message.id), message);
    },
    mutateMessageArray: (state, messages:Array<DB.Message>) => {
      messages.forEach(v => {
        state.messages.set(Number(v.id), v);
      });
    },
    mutateTopicArray: (state, topics:Array<DB.Monthlytopic>) => {
      topics.forEach(v => {
        state.topics.set(Number(v.id), v);
      });
    },
    setTask: (state, v:Net.TaskAndAssignee) => {
      if (!REST.isAdmin()) {
        // Only admins can see all replies
        v.replies = v.replies.filter(r => r.user_id == REST.userId());
      }
      state.tasks.set(Number(v.id), v);
    },
    setTaskReply: (state, v:DB.Taskreply) => {
      let task = state.tasks.get(Number(v.task_id));
      if (task) {
        REST.logDebug("setTaskReply", v, "=>", task)
        let idx = task.replies.findIndex(r => r.user_id == v.user_id)
        if (idx != -1) {
          task.replies[idx] = v
        } else {
          task.replies.push(v);
        }
        state.tasks.set(Number(task.id), task);
      }
    },
    mutateTaskArray: (state, av:Array<Net.TaskAndAssignee>) => {
      av.forEach(v => {
        state.tasks.set(Number(v.id), v);
      });
    },
    deleteTask: (state, id:number) => {
      state.tasks.delete(Number(id));
    },
    setMeeting: (state, meeting:Net.MeetingAndInvitees) => {
      state.meetings.set(Number(meeting.id), meeting);
    },
    mutateMeetingArray: (state, meetings:Array<DB.Meeting>) => {
      meetings.forEach(v => {
        let value = new Net.MeetingAndInvitees(v);
        let meeting = state.meetings.get(Number(v.id));
        if (meeting) {
          value.invitees = meeting.invitees;
          value.participants = meeting.participants;
        }
        state.meetings.set(Number(value.id), value);
      });
    },
    deleteMeeting: (state, id:number) => {
      state.meetings.delete(Number(id));
    },
    setNomenclator: (state, payload:Net.Nomenclator) => {
      state.nomenclator = payload;
    },
  },
  actions: {
    clearReceivedAllFlags: (context) => {
      context.commit("clearReceivedAllFlags");
    },
    clearAll: (context) => {
      context.commit("emptyAll");
      context.commit("setWsState", false);
    },
    setAppTitle: ({ commit }, payload:string) => {
      commit("setAppTitle", payload);
    },
    setSelChatRoomId: ({ commit }, payload:number) => {
      commit("setSelChatRoomId", payload);
    },
    setWsState: ({ commit }, payload:boolean) => {
      if (payload) {
        // at reconnect clear all earlier (potentially out of sync) data
        commit("emptyAll");
      }
      commit("setWsState", payload);
    },
    setNomenclator: ({ commit }, payload : Net.Nomenclator) => {
      commit("setNomenclator", payload);
    },
    setUserPresence: ({ commit }, payload : Net.User_Presence) => {
      commit("setUserPresence", payload);
    },
    setDocument: ({ commit }, payload : DB.Document) => {
      commit("setDocument", payload);
    },
    addQuestionerArray: ({ commit }, payload : Array<Net.FillOutForm>) => {
      commit("mutateQuestionerArray", payload);
    },
    setUser: ({ commit }, payload : Net.Appuser) => {
      commit("setUser", payload);
    },
    addUserArray: ({ commit }, payload : Array<Net.Appuser>) => {
      commit("mutateUserArray", payload);
    },
    setPair: ({ commit }, payload : DB.Amigoskids) => {
      commit("setPair", payload);
    },
    addPairArray: ({ commit }, payload : Array<DB.Amigoskids>) => {
      commit("mutatePairArray", payload);
    },
    addGroupArray: ({ commit }, payload : Array<Net.UserGroupAndMembers>) => {
      commit("mutateGroupArray", payload);
    },
    addRoomArray: ({ commit }, payload : Array<Net.ChatRoomAndMembers>) => {
      commit("mutateRoomArray", payload);
    },
    deleteRoom: ({ commit }, payload : number) => {
      commit("deleteRoom", payload);
    },
    incUnreadMsgCount: ({ commit }, payload : number) => {
      commit("incUnreadMsgCount", payload);
    },
    clearUnreadMsgCount: ({ commit }, payload : number) => {
      commit("clearUnreadMsgCount", payload);
    },
    roomAllMsgReceived: ({ commit }, payload:{ id:number, chunk:Array<DB.Message> }) => {
      commit("roomAllMsgReceived", payload);
    },
    addMessage: ({ commit }, payload : DB.Message) => {
      commit("setMessage", payload);
      commit("incUnreadMsgCount", payload.room_id);
    },
    addMessageArray: ({ commit }, payload : Array<DB.Message>) => {
      commit("mutateMessageArray", payload);
    },
    mutateTopicArray: ({ commit }, payload :Array<DB.Monthlytopic>) => {
      commit("mutateTopicArray", payload);
    },
    setTask: ({ commit }, payload :Net.TaskAndAssignee) => {
      commit("setTask", payload);
    },
    setTaskReply: ({ commit }, payload :DB.Taskreply) => {
      commit("setTaskReply", payload);
    },
    mutateTaskArray: ({ commit }, payload :Array<Net.TaskAndAssignee>) => {
      commit("mutateTaskArray", payload);
    },
    setMeeting: ({ commit }, payload :Net.MeetingAndInvitees) => {
      commit("setMeeting", payload);
    },
    mutateMeetingArray: ({ commit }, payload :Array<DB.Meeting>) => {
      commit("mutateMeetingArray", payload);
    },
    deleteMeeting: ({ commit }, payload :number) => {
      commit("deleteMeeting", payload);
    },

    //  Request data from server
    //
    updateQuestioners: (context) => {
      if (!context.state.allQuestionerRequested) {
        context.state.allQuestionerRequested = true;
        context.dispatch("updateQuestioner", 0);
      }
    },
    updateQuestioner: (context, id:number) => {
      let form = context.getters.getQuestioner(id);
      if ((id < 0) || !form)
      {
        id = Math.abs(id);  // Negative number is force update
        if (pendingQuestionerCalls.has(id)) {
          // earlier call is in progress
          return;
        }
        pendingQuestionerCalls.add(id);
        REST.call("GET", "/filloutform/" + id.toString(),
                  null,
                  (r => {
                      context.dispatch("addQuestionerArray", (r.detail as Array<Net.FillOutForm>));
                      pendingQuestionerCalls.delete(id);
                      return r.detail;
                  }),
                  (async (r) => { 
                    pendingQuestionerCalls.delete(id);
                    REST.logError(" updateQuestioner[", id, "]:", r);
                  }));
      }
    },
    updateUsers: (context) => {
      if (!context.state.allUsersRequested) {
        context.state.allUsersRequested = true;
        context.dispatch("updateUser", 0);
        context.dispatch("updateGroups"); // Without this, users Vue's group list initially only shows the groups the admin is member of.
      }
    },
    updateUser: (context, id:number) => {
      let user = context.getters.getUser(id);
      if ((id < 0) || !user 
          || ((user as Net.Appuser).login.length == 0))
      {
        id = Math.abs(id);  // Negative number is force update
        if (pendingUserCalls.has(id)    // user request running
          || (pendingUserCalls.has(0))) // all users request running 
        {
          // earlier call is in progress
          return;
        }
        pendingUserCalls.add(id);
        REST.call("GET", "/users/" + id.toString(),
                  null,
                  (r => {
                      context.dispatch("addUserArray", (r.detail as Array<Net.Appuser>));
                      pendingUserCalls.delete(id);
                      return r.detail;
                  }),
                  (async (r) => { 
                    pendingUserCalls.delete(id);
                    REST.logError(" getUser[", id, "]:", r);
                  }));
      }
    },
    updateDocument: (context, id:number) => {
      let doc = context.getters.getDocument(id);
      if (!doc)
      {
        id = Math.abs(id);  // Negative number is force update
        if (pendingDocumentCalls.has(id)) {
          // earlier call is in progress
          return;
        }
        REST.logDebug("updateDocument", id, doc, context.getters.getDocuments);
        pendingDocumentCalls.add(id);
        REST.call("GET", "/docs/" + id.toString(),
                  null,
                  (r => {
                      context.dispatch("setDocument", (r.detail as DB.Document));
                      pendingDocumentCalls.delete(id);
                      return r.detail;
                  }),
                  (async (r) => { 
                    pendingDocumentCalls.delete(id);
                    REST.logError(" updateDocument[", id, "]:", r);
                  }));
      }
    },
    updatePairs: (context) => {
      if (!context.state.allPairingRequested) {
        context.state.allPairingRequested = true;
        context.dispatch("updateUser", 0);
        context.dispatch("updatePair", 0);
      }
    },
    updatePair: (context, id:number) => {
      let pair = context.getters.getPair(id);
      if ((id < 0) || !pair)
      {
        id = Math.abs(id);  // Negative number is force update
        if (pendingPairCalls.has(id)) {
          // earlier call is in progress
          return;
        }
        pendingPairCalls.add(id);
        REST.call("GET", "/users/pairs",
                  null,
                  (r => {
                      context.dispatch("addPairArray", (r.detail as Array<DB.Amigoskids>));
                      pendingPairCalls.delete(id);
                      return r.detail;
                  }),
                  (async (r) => { 
                    pendingPairCalls.delete(id);
                    REST.logError(" updatePair[", id, "]:", r);
                  }));
      }
    },
    updateTopics: (context) => {
      if (!context.state.allTopicsRequested) {
        context.state.allTopicsRequested = true;
        REST.call("GET", "/meetings/topics", null,
                  (r => {
                      let rr = (r.detail as Array<DB.Monthlytopic>)
                      context.dispatch("mutateTopicArray", rr);
                      return r.detail;
                  }),
                  (async (r) => { 
                    REST.logError(" updateTopics:", r);
                  }));
      }
    },
    updateTasks: (context) => {
      if (!context.state.allTasksRequested) {
        context.state.allTasksRequested = true;
        context.dispatch("updateTask", 0);
      }
    },
    updateTask: (context, id:number) => {
      let task = context.getters.getTask(id);
      if ((id < 0) || !task)
      {
        id = Math.abs(id);  // Negative number is force update
        pendingTaskCalls.add(id);
        let url = "/tasks/" + id.toString();
        REST.call("GET", url, null,
                  (r => {
                        let rr = (r.detail as Array<Net.TaskAndAssignee>)
                        context.dispatch("mutateTaskArray", rr);
                        pendingTaskCalls.delete(id);
                        return r.detail;
                  }),
                  (async (r) => { 
                    pendingTaskCalls.delete(id);
                    REST.logError(" updateTask:", r);
                  }));
      }
    },
    updateMeetings: (context) => {
      context.dispatch("updateUsers", 0);
      if (!context.state.allMeetingsRequested) {
        context.state.allMeetingsRequested = true;
        context.dispatch("updateMeeting", 0);
      }
    },
    updateMeeting: (context, id:number) => {
      let meeting = context.getters.getMeeting(id);
      if ((id < 0) || !meeting
           || (((meeting as Net.MeetingAndInvitees).invitees.length == 0)
              && ((meeting as Net.MeetingAndInvitees).participants.length == 0)))
      {
        id = Math.abs(id);  // Negative number is force update
        if (pendingMeetingCalls.has(id)) {
          // earlier call is in progress
          return;
        }

        pendingMeetingCalls.add(id);
        let url = "/meetings";
        if (id > 0) {
          url += "/" + id.toString();
        }
        REST.call("GET", url, null,
                  (r => {
                      if (id > 0) {
                        context.dispatch("setMeeting", r.detail as Net.MeetingAndInvitees);
                      } else {
                        let rr = (r.detail as Array<DB.Meeting>)
                        context.dispatch("mutateMeetingArray", rr);
                      }
                      pendingMeetingCalls.delete(id);
                      return r.detail;
                  }),
                  (async (r) => { 
                    pendingMeetingCalls.delete(id);
                    REST.logError(" updateMeeting[", id, "]:", r);
                  }));
      }
    },
    updateGroups: (context) => {
      context.dispatch("updateUsers", 0);
      if (!context.state.allGroupsRequested) {
        context.state.allGroupsRequested = true;
        context.dispatch("updateGroup", 0);
      }
    },
    updateGroup: (context, id:number) => {
      if ((id < 0) || !context.getters.getGroup(id)) {
        id = Math.abs(id);  // Negative number is force update
        if (pendingGroupCalls.has(id)) {
          // earlier call is in progress
          return;
        }

        pendingGroupCalls.add(id);
        REST.call("GET", "/users/groups/" + id.toString(),
                  null,
                  (r => {
                      let rr = (r.detail as Array<Net.UserGroupAndMembers>)
                      context.dispatch("addGroupArray", rr);
                      rr.forEach(room => {
                        room.members.forEach(m => {
                            context.dispatch("updateUser", m.user_id);
                        })
                      });
                      pendingGroupCalls.delete(id);
                      return r.detail;
                  }),
                  (async (r) => { 
                    pendingGroupCalls.delete(id);
                    REST.logError(" getRoom[", id, "]:", r);
                  }));
      }
    },
    updateRooms: (context) => {
      context.dispatch("updateUsers", 0);
      if (!context.state.allRoomsRequested) {
        context.state.allRoomsRequested = true;
        context.dispatch("updateRoom", 0);
      }
    },
    updateRoomMessages: (context, room_id:number) => {
      // id == 0 means all rooms
      if (room_id != 0) {
        let room = context.getters.getRoom(room_id);
        if (room && !room.allMsgReceived) {
          if (pendingRoomMessagesCalls.has(room_id)) {
            // earlier call is in progress
            return;
          }
          pendingRoomMessagesCalls.add(room_id);
          let reqBody = new Net.GetChatChunk();
          reqBody.room_id = room.id;
          reqBody.last_id =  room.firstMessageId;
          REST.call("GET", "/messages", reqBody,
                    (r => {
                        let msgChunk = r.detail as Array<DB.Message>
                        context.dispatch("addMessageArray", msgChunk);
                        context.dispatch("roomAllMsgReceived", { id:room_id, chunk:msgChunk });
                        pendingRoomMessagesCalls.delete(room_id);
                        return r.detail;
                    }),
                    (async (r) => { 
                      pendingRoomMessagesCalls.delete(room_id);
                      REST.logError(" getRoom:", r);
                    }));
        }
      }
    },
    updateRoom: (context, room_id:number) => {
      if ((room_id < 0) || !context.getters.getRoom(room_id)) {
        room_id = Math.abs(room_id);  // Negative number is force update
        if (!pendingRoomCalls.has(room_id)) {
          // no other call is in progress
          pendingRoomCalls.add(room_id);
          REST.call("GET", "/messages/rooms/" + room_id.toString(),
                    null,
                    (r => {
                        let rr = (r.detail as Array<Net.ChatRoomAndMembers>)
                        context.dispatch("addRoomArray", rr);
                        rr.forEach(room => {
                          room.members.forEach(m => {
                            if (m.is_member_group) {
                              context.dispatch("updateGroup", m.member_id);
                            } else {
                              context.dispatch("updateUser", m.member_id);
                            }
                          })
                        });
                        // Get Room messages
                        context.dispatch("updateRoomMessages", room_id);
                        pendingRoomCalls.delete(room_id);
                        return r.detail;
                    }),
                    (async (r) => { 
                      pendingRoomCalls.delete(room_id);
                      REST.logError(" getRoom[", room_id, "]:", r);
                    }));
        }
      } else {
        // Get Room messages
        context.dispatch("updateRoomMessages", room_id);
      }
    },
    getNomenclator: (context) => {
      REST.call("GET", "/nomenclator", null,
                (r => {
                    context.dispatch("setNomenclator", (r.detail as Net.Nomenclator));
                    return r.detail;
                }),
                (async (r) => { 
                  REST.logError(" getNomenclator:", r);
                }));
    },

    // Connect to WebSocket if logged in
    //
    connectWebSocket(context) {
      if ((!context.state.wsConnected) 
          && (REST.wsSessionToken() != null))
      {
        if (ws != null) {
          REST.logDebug("ACTION connectWebSocket: CLOSE", ws);
          ws.close();
          ws = null;
        }

        // after reconnect re-read all content
        context.dispatch("clearReceivedAllFlags");
  
        REST.logDebug("ACTION connectWebSocket: CONNECTING", document.cookie);
        ws = new WebSocket(REST.wsURL(),
                          ['json', 'xml', 'text']);
        ws.addEventListener('close', () => {
            REST.logDebug("WebSocket closed");
            context.dispatch("setWsState", false);
            if (REST.wsSessionToken() != null) {
              setTimeout(() => {
                // wait 10 seconds before reconnecting
                context.dispatch("connectWebSocket", false);
              }, 10000);
            }
        });
        ws.addEventListener('error', (err) => {
            REST.logError("WebSocket error: ", err);
            context.dispatch("setWsState", false);
        });
        ws.addEventListener('open', () => {
            REST.logDebug("WebSocket opened...", document.cookie);
            document.cookie = "session_token=" + REST.wsSessionToken();
            context.dispatch("setWsState", true);

            // Update all data
            context.dispatch("updateQuestioners");
            context.dispatch("updateTopics");
            context.dispatch("updateUsers");
            context.dispatch("updateGroups");
            context.dispatch("updatePairs");
            context.dispatch("updateTasks");
            context.dispatch("updateMeetings");
            context.dispatch("updateRooms");
        });
        // Handle events coming from backend
        //
        ws.addEventListener('message', event => {
            const data = JSON.parse(event.data);  // data.body is message specific
            REST.logDebug("event.data: ", data);
            switch (data.type) {
                case Net.UserPresence: {
                  context.dispatch("setUserPresence", data.body as Net.User_Presence);
                  break
                }
                case Net.FilloutForm: {
                  let payload = new Array<Net.FillOutForm>();
                  payload.push(data.body as Net.FillOutForm);
                  context.dispatch("addQuestionerArray", payload);
                  break
                }
                case Net.Task: {
                  context.dispatch("setTask",
                                  (data.body as Net.TaskAndAssignee));
                  break
                }
                case Net.TaskReply: {
                  context.dispatch("setTaskReply",
                                  (data.body as DB.Taskreply));
                  break
                }
                case Net.TaskDelete: {
                  context.dispatch("deleteTask", (data.body as number));
                  break
                }
                case Net.Meeting: {
                  context.dispatch("setMeeting",
                                  (data.body as Net.MeetingAndInvitees));
                  break
                }
                case Net.MeetingDeleted: {
                  context.dispatch("deleteMeeting", (data.body as number));
                  break
                }
                case Net.MonthlyTopic: {
                  context.dispatch("mutateTopicArray",
                                  [(data.body as DB.Monthlytopic)]);
                  break
                }
                case Net.ChatRoom: {
                  context.dispatch("updateRoom", -1 * (data.body as number));
                  break
                }
                case Net.UserGroup: {
                  // With User group upgrade we receive
                  // the group and it's new members
                  context.dispatch("addGroupArray",
                                  [(data.body as Net.UserGroupAndMembers)]);
                  break
                }
                case Net.RoomDeleted: {
                  context.dispatch("deleteRoom", (data.body as number));
                  break
                }
                case Net.Chat: {
                  context.dispatch("addMessage", data.body as DB.Message);
                  break
                }
                case Net.Pairing: {
                  context.dispatch("setPair",
                                  (data.body as DB.Amigoskids));
                  break
                }
                case Net.AppUserMod: {
                  context.dispatch("setUser", (data.body as Net.Appuser))
                }
            }
        });
      }
    }
  },
  modules: {
  }
})
