var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-snackbar',{attrs:{"vertical":"","centered":"","color":"error","timeout":_vm.snackbar_timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t("close_btn"))+" ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('pre',[_vm._v(_vm._s(_vm.snackbar_text))])]),_c('v-app-bar',{attrs:{"app":"","color":"secondary"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"shrink display-1",on:{"click":_vm.openNav}},[_vm._v("mdi-menu")])],1),_c('v-spacer'),(!_vm.canEditTitle())?_c('span',{staticClass:"mr-1 title"},[_vm._v(_vm._s(_vm.appTitle))]):_c('v-text-field',{staticClass:"mr-1 title",attrs:{"label":_vm.$t('chat_title'),"append-outer-icon":"mdi-auto-upload","single-line":"","hide-details":"","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateRoomTitle()},"click:append-outer":function($event){return _vm.updateRoomTitle()}},model:{value:(_vm.appTitle),callback:function ($$v) {_vm.appTitle=$$v},expression:"appTitle"}}),_c('v-spacer'),_c('div',{staticClass:"text-center d-flex align-center justify-space-around"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.wsConnected)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary darken-2"}},'v-icon',attrs,false),on),[_vm._v("mdi-access-point-network")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-access-point-network-off")])]}}])},[(_vm.wsConnected)?_c('span',[_vm._v(_vm._s(_vm.$t('ws_connected_lbl')))]):_c('span',[_vm._v(_vm._s(_vm.$t('ws_not_connected_lbl')))])]),_c('v-spacer'),(_vm.loggedIn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-2"},on:{"click":function($event){return _vm.logout()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-exit-run")])],1)]}}],null,false,1726414449)},[_c('span',[_vm._v(_vm._s(_vm.$t('logout_btn')))])]):_vm._e()],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","outlined":""}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require('./assets/flags/' + _vm.$i18n.locale + '.svg'),"width":"20px","color":"secondary"}})],1)]}}])},[_c('v-list',_vm._l((['en','hu']),function(lang){return _c('v-list-item',{key:lang,on:{"click":function($event){_vm.$i18n.locale = lang}}},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"src":require('./assets/flags/' + lang + '.svg'),"width":"30px"}}),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(lang))])],1)],1)}),1)],1)],1),_c('v-main',[_c('router-view')],1),_c('v-footer',{attrs:{"color":"indigo","app":""}},[_c('span',{staticClass:"white--text"},[_vm._v("© 2021 MS")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }