import DB from './db_struct';

//
//     Structures sent over the network
//

namespace Net {
    // Event types
    export const UserPresence   = "UserPresence";
    export const FilloutForm    = "FilloutForm";
    export const Task           = "Task";
    export const TaskReply      = "TaskReply";
    export const TaskDelete     = "TaskDelete";
    export const Meeting        = "Meeting";
    export const MeetingDeleted = "MeetingDeleted";
    export const ChatRoom       = "ChatRoom";
    export const UserGroup      = "UserGroup";
    export const RoomDeleted    = "RoomDeleted";
    export const Chat           = "Chat";
    export const MonthlyTopic   = "MonthlyTopic";
    export const Pairing        = "Pairing";
    export const Assignment     = "Assignment";
    export const AppUserMod     = "AppUserMod";

    export const GROUP_ROOM_PREFIX      = "UG_";
    export const SYSTEM_ROOM_PREFIX     = "S_";
    export const PERSONAL_ROOM_PREFIX   = "P_";
    export const MEETING_ROOM_PREFIX    = "M_";
    export const ROOM_USER_PREFIX       = "U";
    export const ROOM_GROUP_PREFIX      = "G";
    
    export const RE_htmlTag = new RegExp('<\\S+>.+</\\S+>', "ig");
    export const RE_httpLink = new RegExp('(www\\.[^\\s\']+|http[s]{0,1}:\\/\\/[^\\s]+)', "ig");
    export const RE_boldText = new RegExp('\\*\\*(([^\\*]|\\*[^\\*])+)\\*\\*', "ig");
    export const RE_tagging = new RegExp('^\\{\\{TAGGING\\[([^\\]]+)\\]\\}\\}');
    export const RE_smily = new RegExp('\\{\\{(([^\\}]|\\}[^\\}])+)\\}\\}', "ig");
    export const TAGGING_PREFIX = "{{TAGGING["
    export const TAGGING_SUFFIX = "]}}"

    export const AmigoProfileFormId     = 1;
    export const KidProfileFormId       = 2;
    export const KidDynamicGroupPrefix  = "KID:";
    export const AmigoDynamicGroupPrefix= "AMIGO:";
    export const AdminsGroupName        = "Admins";
    export const AdmigosGroupName       = "Amigos";
    export const KidAdmigoGroupPrefix   = "KID_AMIGOS__";
    export const PairingRoomChildView   = "my_amigos";
    export const PairingRoomAmigoView   = "my_proteje";
    export const AmigoSysRoom           = "AMIGOS_SYSTEM";
    export const KidSysRoom             = "KIDS_SYSTEM";
    export const NewsRoom               = "NEWS_ROOM";
    export const SysChatRooms           = [AmigoSysRoom, KidSysRoom]

    // encoded in message content
    export const MsgTag_Id_RE           = new RegExp('ID:', "ig");
    export const MsgTag_Id              = 'ID:';
    export const MsgTag_Title_RE        = new RegExp('\\$\\$TITLE\\$\\$', "ig");
    export const MsgTag_Title           = '$$TITLE$$';
    export const MsgTag_Date_RE         = new RegExp('\\$\\$DATE\\$\\$', "ig");
    export const MsgTag_Date            = '$$DATE$$';
    export const MsgTag_Description_RE  = new RegExp('\\$\\$DESCRIPTION\\$\\$', "ig");
    export const MsgTag_Description     = '$$DESCRIPTION$$';
    export const MsgTag_Open_RE         = new RegExp('<', "ig");
    export const MsgTag_Close_RE        = new RegExp('>', "ig");
    export const MsgTag_I18n_Prefix     = "$T{";
    export const MsgTag_I18n_End        = "}";
    export const MsgTag_I18n_RE         = new RegExp('\\$T\\{([^\\}]+)\\}', "ig");

    // Database constants
    // Inserted into the DB at creation
    export const MsgType_Chat           = "MESSAGE";
    export const MsgType_Request        = "QUESTION";
    export const MsgType_Task           = "TASK";
    export const MsgType_Meeting        = "MEETING";
    export const MsgType_Response       = "RESPONSE";

    export const TaskReplyType_Ack      = "ACK";
    export const TaskReplyType_YesNo    = "YESNO";
    export const TaskReplyType_Option   = "OPTION";
    export const TaskReplyType_Reply    = "REPLY";
    export const TaskReplyType_Upload   = "UPLOAD";
    export const TaskReplyType_FillOut  = "FILLOUT";

    export const Recurrence_Once        = "ONCE";
    export const Recurrence_Daily       = "DAILY";
    export const Recurrence_Weekly      = "WEEKLY";
    export const Recurrence_BiWeekly    = "BIWEEKLY";
    export const Recurrence_DayOfWeek   = "DAY_OF_WEEK";
    export const Recurrence_DayOfBiWeek = "DAY_OF_BIWEEK";
    export const Recurrence_Monthly     = "MONTHLY";
    export const Recurrence_DayOfMonth  = "FIRSTDAY_OF_MONTH";
    export const Recurrence_Quoterly    = "QUOTERLY";

    export const MeetType_Private       = "PRIVATE";
    export const MeetType_Occupation    = "OCCUPATION";
    export const MeetType_GroupEvent    = "GROUP_OCCUPATION";
    export const MeetType_AmigosOnly    = "AMIGOS_ONLY";

    export const Participation_Accept   = "ACCEPTED";
    export const Participation_Declined = "DECLINED";
    export const Participation_Tentative= "TENTATIVE";

    export const DocType_Public         = "PUBLIC";             // no link
    export const DocType_Chat           = "CHAT";               // document linked to chat room
    export const DocType_Assignment     = "TOPIC_CHALLENGE";    // homework assignment for a group event
    export const DocType_TopicAmigo     = "TOPIC_AMIGO";        // amigo only topic document
    export const DocType_Homework       = "TOPIC_HOMEWORK";     // upload solution for homework assignment
    export const DocType_TaskReply      = "TASK_REPLY";         // reply to an UPLOAD type task
    export const DocType_MeetingAmigo   = "MEETING_AMIGO";      // amigo only meeting document
    export const DocType_MeetPub        = "MEETING_PUBLIC";     // homework
    export const DocType_MeetPriv       = "MEETING_PRIVATE";    // owner/uploader/admin only
    export const DocType_MeetHomework   = "MEETING_HOMEWORK";   // homework uploaded by kid
    export const DocType_MeetMinutes    = "MEETING_MINUTES";    // meeting minutes
    export const DocType_MeetFeedback   = "MEETING_FEEDBACK";   // meeting feedback (amigo and kid)
    
    // public document type for user avatar: "11"

    export const RestrictedDocuments    = [DocType_MeetPriv, DocType_MeetFeedback, DocType_MeetMinutes];
    export const KidsVisibleDocuments   = [DocType_Public, DocType_MeetHomework, DocType_MeetPub, DocType_Assignment];

    export const dynaGroupConditionSplit = (condition:string) => { 
        if (condition) {
            let idx = condition.search(':');
            if (idx >= 0) {
                condition = condition.substring(idx+1);
                idx = condition.search('=');
                if (idx >= 0) {
                    return {kid:condition.startsWith(KidDynamicGroupPrefix),
                                field:condition.substring(0, idx),
                                value:condition.substring(idx+1)};
                }
            }
        }
        return {kid:false,
                field:"",
                value:""};
    };

    export class ChatRoomUpdate {
        room_id         = 0;
        user_members    = new Set<number>();
        group_members   = new Set<number>();
    };

    export const CHAT_MSG_CHUNK_SIZE    = 50;    // chat messages read at a time
    export class GetChatChunk {
        room_id         = 0;
        last_id         = 0;
        last_time       = new Date(0);
    };

    export class User_Presence {
        userPresence = new Array<[number, boolean]>();
        constructor(presence?: [number, boolean]) {
            if (presence) {
              this.userPresence.push(presence);
            }
        }
        presentUsers(presentUsers: Set<number>) {
            presentUsers.forEach(uId => {
                this.userPresence.push([uId, true])
            });
            return this;
        }
    }

    // getNomenclator
    export class Nomenclator {
        meetingTypes = new Array<DB.Meetingtype>();
        meetingRecurrences = new Array<DB.Meetingrecurrence>();
        participantStates = new Array<DB.Participationstate>();
        messageTypes = new Array<DB.Messagetype>();
        documentTypes = new Array<DB.Documenttype>();
        taskTypes = new Array<DB.Tasktype>();
    }

    export class FillOutForm extends DB.Filloutform {
        items = new Array<DB.Filloutitem>();
        constructor(o:DB.Filloutform) {
            super(o);
            this.items = new Array<DB.Filloutitem>();
        }
    }

    // post("/users/create",            { users.register(req, res); });
    // post("/users/register",          { users.register(req, res); });
    export class CreateUser extends DB.Appuser {
        memberOf = new Array<number>();
        constructor(o:UpdateUser) {
            super(o as DB.Appuser);
            this.memberOf = o.new_memberOf;
        }
    }

    // get("/users/:id",                { users.getUser(req, res); });
    export class Appuser extends DB.Appuser {
        is_present: boolean = false;
        constructor(o:DB.Appuser) {
            super(o);
            this.password = "";
        }
    }

    // put("/users/:id",                { users.updateUser(req, res); });
    export class UpdateUser extends DB.Appuser {
        new_memberOf : Array<number> = [];
        del_memberOf : Array<number> = [];
        constructor(o?:DB.Appuser) {
            super(o);
            this.password = "";
        }
    }

    // get("/users/all",                { users.getAllUser(req, res); });
    // -> DB.Appuser[]
    
    // post("/users/login",             { users.login(req, res); });
    export class Login {
        login:string = "";
        password:string = "";
    }
    export class Login_Resp extends DB.Appuser {
        rest_api_token: string = "";
        ws_api_token: string = "";
        session_id: number = 0;
        constructor(o:DB.Appuser) {
            super(o);
            this.password = "";
        }
    }

    // put("/users/logout",             { users.logout(req, res); });
    // -> null

    // post("/users/webpush",           { users.setWebPush(req, res); });
    // delete("/users/webpush",         { users.clearWebPush(req, res); });

    // get("/users/groups/my",          { users.getMyGroups(req, res); });
    // -> DB.Group_members_view[]

    // get("/users/groups/all",         { users.getAllGroups(req, res); });
    // -> DB.Usergroup[]

    // get("/users/groups/:id",         { users.getGroup(req, res); });
    export class UserGroupAndMembers extends DB.Usergroup {
        members = new Array<DB.Groupmembers>();
        constructor(o:DB.Usergroup) {
            super(o);
            this.members = new Array<DB.Groupmembers>();
        }
    }
    
    // post("/users/groups",            { users.createGroup(req, res); });
    export class CreateGroup extends DB.Usergroup {
        members : Array<number> = [];
        constructor(o:DB.Usergroup) {
            super(o);
            this.members = new Array<number>();
        }
    }

    // put("/users/groups/:id",         { users.updateGroup(req, res); });
    export class UpdateGroup extends DB.Usergroup {
        new_members : Array<number> = [];
        del_members : Array<number> = [];
        constructor(o:DB.Usergroup) {
            super(o);
            this.new_members = new Array<number>();
            this.del_members = new Array<number>();
        }
    }

    // get("/users/amigopairs",         { users.getAmigosOfKid(req, res); });
    // get("/users/kidpairs",           { users.getKidsOfAmigo(req, res); });
    // get("/users/amigopairs/:id",     { users.getAmigosOfKid(req, res); });   // admin only
    // get("/users/kidpairs/:id",       { users.getKidsOfAmigo(req, res); });   // admin only
    // post("/users/akpairs/:aid/:kid", { users.createAmigoKidPairs(req, res); });
    // delete("/users/akpairs/:id",     { users.deleteAmigoKidPairs(req, res); });

    // get("/docs",                     { docs.listDocuments(req, res); });       // list documents by some criteria
    // get("/docs/meeting/:id",         { docs.listMeetingDocs(req, res); });     // list documents belonging to a meeting
    // get("/docs/download/:id",        { docs.download(req, res); });            // download link to document
    // delete("/docs/:id",              { docs.markDeleted(req, res); });
    // post("/docs/:type/:refId",       { docs.upload(req, res) });               // upload

    // get("/meetings/topics",          { meetings.getMonthlyTopics(req, res); });
    // put("/meetings/topics/:id",      { meetings.amendMonthlyTopic(req, res); });
    // post("/meetings/topics",         { meetings.createMonthlyTopic(req, res); });
    //

    // get("/meetings/:id",             { meetings.getMeetingDetails(req, res); });
    // get("/meetings",                 { meetings.getMeetings(req, res); });     // return user/public calendar events for period
    // post("/meetings",                { meetings.createMeeting(req, res); });   // new calendar
    // put("/meetings/attendance/:id")  { meetings.setAttendance(req, res); });
    export class MeetingAndInvitees extends DB.Meeting {
        invitees = new Array<DB.Meetinginvitee>();
        participants = new Array<DB.Meetingparticipant>();
        docs = new Array<DB.Document>();
        constructor(o:DB.Meeting) {
            super(o);
            this.invitees = new Array<DB.Meetinginvitee>();
            this.participants = new Array<DB.Meetingparticipant>();
            this.docs = new Array<DB.Document>();
        }
    }
    // put("/meetings/:id",             { meetings.amendMeeting(req, res); });
    export class MeetingAmend extends DB.Meeting {
        new_invitees : Array<DB.Meetinginvitee> = [];
        del_invitees : Array<DB.Meetinginvitee> = [];
        constructor(o:DB.Meeting) {
            super(o);
            this.new_invitees = new Array<DB.Meetinginvitee>();
            this.del_invitees = new Array<DB.Meetinginvitee>();
        }
    }

    // delete("/meetings/:id",          { meetings.cancelMeeting(req, res); });
    // put("/meetings/subscribe/:id",   { meetings.subscribeGroupEvent(req, res); });
    // put("/meetings/tentative/:id",   { meetings.tentativeMeeting(req, res); });
    // put("/meetings/accept/:id",      { meetings.acceptMeeting(req, res); });
    // put("/meetings/decline/:id",     { meetings.declineMeeting(req, res); });
    // post("/meetings/minutes",        { meetings.createMinutes(req, res); });
    // get("/meetings/minutes/:id",     { meetings.getMeetingMinutes(req, res); });
    // put("/meetings/minutes/:id",     { meetings.amendMinute(req, res); });
    // post("/meetings/feedback",       { meetings.createFeedback(req, res); });
    // get("/meetings/feedback/:id",    { meetings.getMeetingFeedbacks(req, res); });
    // put("/meetings/feedback/:id",    { meetings.amendFeedback(req, res); });

    export class TaskAndAssignee extends DB.Task {
        assignees = new Array<DB.Taskassignee>();
        replies = new Array<DB.Taskreply>();
        constructor(o:DB.Task) {
            super(o);
            this.assignees = new Array<DB.Taskassignee>();
            this.replies = new Array<DB.Taskreply>();
        }
    }
    export class TaskAmend extends DB.Task {
        new_assignees : Array<DB.Taskassignee> = [];
        del_assignees : Array<DB.Taskassignee> = [];
        constructor(o:DB.Task) {
            super(o);
            this.new_assignees = new Array<DB.Taskassignee>();
            this.del_assignees = new Array<DB.Taskassignee>();
        }
    }

    // get("/messages",                 { messages.getMessages(req, res); });
    // post("/messages",                { messages.createMessage(req, res); });
    // post("/messages/ack/:id",        { messages.ackMessage(req, res); });
    // post("/messages/rooms",          { messages.createRoom(req, res); });              
    // post("/messages/rmbr/:id/:mid",  { messages.addRoomMember(req, res); });              
    // delete("/messages/rmbr/:id/:mid",{ messages.removeRoomMember(req, res); });              

    // get("/messages/rooms",           { messages.getRooms(req, res); });
    // get("/messages/rooms/:id",       { messages.getRooms(req, res); });
    export class ChatRoomAndMembers extends DB.Chatroom {
        members = new Array<DB.Chatroommembers>();
        unreadMsgCount : number = 0;
        firstMessageId : number = 0;
        allMsgReceived : boolean = false;
        constructor(o:DB.Chatroom) {
            super(o);
            this.members = new Array<DB.Chatroommembers>();
            this.unreadMsgCount = 0;
            this.allMsgReceived = false;
        }
    }

    // put("/messages/rooms/:id",       { messages.updateRoom(req, res); });

}
export default Net;