import { REST } from './rest_call'
import DB from '../../../common/db_struct';
import Net from '../../../common/net_interface';
import store from '../store'
import VueI18n from 'vue-i18n'
import { VTabItem } from 'vuetify/lib';

export const DATA = {
    /* Functions */
    isWsConnected,
    groupOccupationTypeId,
    getGroupOccupationMeetings,
    recurrenceOnceTypeId,
    responseChatTypeId,
    chatTypeId,
    getNomenIdCode,
    getNomenCodeId,
    idFromChatContent,
    acceptedTypeId,
    declinedTypeId,
    tentativeTypeId,
    chatHistory,
    isRequestChat,
    isResponseChat,
    boolCmp,
    strCmp,
    sortTasks,
    dateToStr,
    dateToStrIso,
    dateToTimeStrNoSec,
    dateToDateTimeStrNoSec,
    dateToTimeStr,
    fileSizeToString,
    getRoomTitle,
    getRoomMessages,
    addDayDivider,
    isSystemRoom,
    nonSystemRooms,
    adminInNewsRoom,
    getUniqueRoomId,
    userPresent,
    userName,
    getGroupOrUserName,
    userPhone,
    userEmail,
    getUserAvatarLink,
    getMeetingTasks,
    getMeetingDocuments,
    getTaskReplyDocuments,
    getMapFromString,
    validateQuestioner,
    getTopicDocuments,
    groupName,
    isReadonlyGroup,
    getUser,
    getGroup,
    sameInvitee,
    sameAssignee,
    hasRepliedIcon,
    isAssigned,
    getAssignedTasks,
    getTaskPossibleAssignees,
    getReplyForTask,
    isTaskAssignedToMe,
    getReplyDateForTask,
    isInvited,
    getUserType,
    getInviteeType,
    filterUser,
    filterInvitee,
    isKidAmigoPair,
    areGroupMembers,
    getPossibleChatInvitees,
    getMeetingPossibleInvitees,
    getGroupPossibleMembers,
    getDocName,
    docIsImage,
    docIsPdf,
    isUserMemberOfGroup,
    isMeetingInvitee,
    meetingChatRoomId,
    isMeetingParticipant,
    getParticipationState,
    hasUserAcceptedMeeting,
    getKidParticipantsCount,
    getAmigoParticipantsCount,
    getMeetingColor,
    checkMeetingUsers,
    meetingsForRange,
    getMeetingTitle,
    hasAcceptedIcon,
    getTableIcon,
    getGroupsOfUserId,
    setChatSelectFn,
    setTodoSelectFn,
    isProfileForm,
    getProfileFormFields,
    getFilloutFormList,
    getUserProfileJSON,
    getUserProfileFormId,
};

export var ChatRoomSelectFn : (roomId:any) => void;
function setChatSelectFn(fn: (roomId: any) => void) {
  ChatRoomSelectFn = fn;
}
export var TodoSelectFn : () => void;
function setTodoSelectFn(fn: () => void) {
  TodoSelectFn = fn;
}

export class KeyedInvitee {
  data : DB.Meetinginvitee = new DB.Meetinginvitee();
  key : string = "U_0";
  type : string = "0";
  isSelectable : boolean = true;
  constructor(d:DB.Meetinginvitee) {
      this.data = d;
      this.key = ((d.is_member_group ? "G_" : "U_") + d.member_id.toString());
      this.type = getInviteeType(this.data);
      this.isSelectable = true;
  }
}

export class ChatRoomView {
  room : Net.ChatRoomAndMembers;
  showUser : (user : Net.Appuser) => boolean = (user) => !!user;
  showMessagesTaggedForOthers : () => boolean = () => true;
  constructor(room : Net.ChatRoomAndMembers) {
    this.room = room;
    if (room.unique_id.startsWith(Net.MEETING_ROOM_PREFIX)) {
      this.showUser = (user) => user && (
        user.id == REST.userId() ||
        user.is_admin || REST.isAdmin() ||
        user.is_amigo || REST.isAmigo());
      this.showMessagesTaggedForOthers = () =>
        REST.isAdmin() || REST.isAmigo();
    }
  }

  isMessageVisible(message:DB.Message) {
    if (message.sender_id == REST.userId()) {
      return true;
    }

    if (!this.showUser(getUser(message.sender_id) as Net.Appuser)) {
      return false;
    }

    if (!this.showMessagesTaggedForOthers() && this.isMessageTaggedForOthers(message)) {
      return false;
    }

    return true;
  }

  isMessageTaggedForOthers(message:DB.Message) {
    if (message.content.startsWith(Net.TAGGING_PREFIX)) {
      let taggingMatch = Net.RE_tagging.exec(message.content);
      if (taggingMatch) {
        let targetUserIds = taggingMatch[1].split(',');
        for (let targetUserId of targetUserIds) {
          if (Number(REST.userId()) == Number(targetUserId)) {
            // tagged for user
            return false;
          }
        }
        // tagged but not for user
        return true;
      }
    }
    // not tagged
    return false;
  }
}

export class MessageStream extends DB.Message {
  items : Array<DB.Message> = new Array<DB.Message>();

  static isStream(msg : DB.Message) : boolean {
    let msgType = store.getters.nomenclator.messageTypes.find((t:DB.Messagetype) => t.id == msg.type_id)
    return ((msgType && msgType.code === Net.MsgType_Request)
            || ((msgType && msgType.code === Net.MsgType_Response) && (msg.ref_id > 0))
            || ((msg instanceof MessageStream) && ((msg as MessageStream).items.length > 1)));
  }
  static hasReply(msg : DB.Message) : boolean {
    return (MessageStream.isStream(msg)
            && ((msg instanceof MessageStream) && ((msg as MessageStream).items.length > 1)));
  }

  streamRoot() : DB.Message {
    let rv : DB.Message = this
    this.items.forEach( m => {
      if (((m.id < rv.id) && (rv.ref_id == 0))
          || ((m.ref_id>0) && (m.ref_id < rv.ref_id)))
      {
        rv = m;
      }
    })
    return rv;
  }
  addStreamMessage(m:DB.Message, allowFirst:boolean) {
    // order messages by date
    let added = false;
    let cmpDate = new Date(m.send_time).valueOf();
    for (let l=this.items.length; l>0; l--) {
      if (cmpDate >= new Date(this.items[l-1].send_time).valueOf()) {
        this.items.splice(l, 0, m);
        added = true;
        break;
      }
    }
    if (!added) {
      this.items.splice(0, 0, m);
      // we want the oldest message to be the first
      if (allowFirst) {
        this.setContent(m);
      }
    }
  }
  filterStreamMessages(predicate: (value: DB.Message) => unknown) {
    this.items = this.items.filter(predicate);
  }

  hasMessageFromUserId(userId:number) : boolean {
    if (userId > 0) {
      for (let m of this.items) {
        if (m.sender_id == userId) {
          return true;
        }
      }
    }
    return false;
  }
  constructor(o:DB.Message) {
      super(o);
      this.items = new Array<DB.Message>();
      this.items.push(o);
  }
}

function isProfileForm(item:DB.Filloutitem) {
  return ((item.id == Net.AmigoProfileFormId) || (item.id == Net.KidProfileFormId)) 
}
function isWsConnected() {
  return store.getters.isWsConnected;
}
function dateToStr(dateStr:any) {
  let date = new Date(dateStr);
  return date.getFullYear().toString().padStart(4,'0') 
          + "/" + (date.getMonth()+1).toString().padStart(2,'0')
          + "/" + date.getDate().toString().padStart(2,'0');
}
function dateToStrIso(dateStr:any) {
  let date = new Date(dateStr);
  return date.getFullYear().toString().padStart(4,'0') 
          + "-" + (date.getMonth()+1).toString().padStart(2,'0')
          + "-" + date.getDate().toString().padStart(2,'0');
}
function dateToTimeStrNoSec(dateStr:any) {
  let date = new Date(dateStr);
  return date.getHours().toString().padStart(2,'0') 
          + ":" + date.getMinutes().toString().padStart(2,'0');
}
function dateToDateTimeStrNoSec(dateStr:any) {
  return dateToStr(dateStr) + " " + dateToTimeStrNoSec(dateStr);
}
function dateToTimeStr(dateStr:any) {
  let date = new Date(dateStr);
  return date.getHours().toString().padStart(2,'0') 
          + ":" + date.getMinutes().toString().padStart(2,'0')
          + ":" + date.getSeconds().toString().padStart(2,'0');
}
function fileSizeToString(fileSize:number) {
  let size = Number(fileSize);
  if (size < 1024) {
    return size + "B";
  }
  if (size < 1024 * 1024) {
    return (size / 1024).toFixed(0) + "KB";
  }
  if (size < 1024 * 1024 * 1024) {
    return (size / (1024 * 1024)).toFixed(0) + "MB";
  }
  return (size / (1024 * 1024 * 1024)).toFixed(1) + "GB";
}
function getNomenIdCode(nomenclator:Array<any>, id:number) {
  let rv = "";
  nomenclator.forEach(rt => {
    if (rt.id === id) {
      rv = rt.code;
    }
  });
  return rv;
}
function getNomenCodeId(nomenclator:Array<any>, code:string) {
  let rv = 0;
  nomenclator.forEach(rt => {
    if (rt.code === code) {
      rv = rt.id;
    }
  });
  return rv;
}
function getDocName(docId:number) {
  let doc = store.getters.getDocument(docId) as DB.Document;
  if (!doc) {
    store.dispatch('updateDocument', docId);
  } else {
    return doc.description;
  }
  return "...";
}
function docIsImage(docId:number) {
  if (docId > 0) {
    // Message has an attachment
    let doc = store.getters.getDocument(docId) as DB.Document;
    if (!doc) {
      store.dispatch('updateDocument', docId);
    } else {
      if ((doc.description.toLowerCase().endsWith(".jpg"))
        || (doc.description.toLowerCase().endsWith(".jpeg"))
        || (doc.description.toLowerCase().endsWith(".png"))) 
      {
        return true;
      }
    }
  }
  return false
}
function docIsPdf(docId:number) {
  if (docId > 0) {
    // Message has an attachment
    let doc = store.getters.getDocument(docId) as DB.Document;
    if (!doc) {
      store.dispatch('updateDocument', docId);
    } else {
      if (doc.description.toLowerCase().endsWith(".pdf")) 
      {
        return true;
      }
    }
  }
  return false
}
function acceptedTypeId() {
  return getNomenCodeId(store.getters.nomenclator.participantStates, Net.Participation_Accept);
}
function declinedTypeId() {
  return getNomenCodeId(store.getters.nomenclator.participantStates, Net.Participation_Declined);
}
function tentativeTypeId() {
  return getNomenCodeId(store.getters.nomenclator.participantStates, Net.Participation_Tentative);
}
function recurrenceOnceTypeId() {
  return getNomenCodeId(store.getters.nomenclator.meetingRecurrences, Net.Recurrence_Once);
}
function groupOccupationTypeId() {
  return getNomenCodeId(store.getters.nomenclator.meetingTypes, Net.MeetType_GroupEvent);
}
function responseChatTypeId() {
  return getNomenCodeId(store.getters.nomenclator.messageTypes, Net.MsgType_Response);
}
function chatTypeId() {
  return getNomenCodeId(store.getters.nomenclator.messageTypes, Net.MsgType_Chat);
}
function idFromChatContent(content:string) {
  let parts = content.split(" ");
  if ((parts.length == 1)
      || (!parts[0].startsWith("ID:")))
  {
    return Number(parts[0]);
  }
  if (parts[0].startsWith(Net.MsgTag_Id)) {
    return Number(parts[0].substr(Net.MsgTag_Id.length))
  }
  REST.logError(" Cannot find ID in chat content:", content)
  return 0;
}
function getProfileFormFields(kids:boolean) {
  let rv: { text: string; value: string; disabled: boolean;
          type:string, values:string}[] = []
  store.getters.getQuestioners.forEach((form:Net.FillOutForm) => {
            if ((kids && (form.id == Net.KidProfileFormId))
                || (!kids && (form.id == Net.AmigoProfileFormId)))
            {
              // 1-TEXT, 2-RADIO, 3-MULTISEL, 4-COMBO, 5-COMBO-MULTI, 6-FILE-UPLOAD
              let types = ["TEXT", "LIST", "LIST", "LIST", "LIST", null]
              form.items.forEach(item => {
                if ((item.item_type > 0) && (item.item_type < types.length))
                {
                  let comboItem = {text: item.label,
                                  value: item.name,
                                  disabled: false,
                                  type: types[item.item_type-1] as string,
                                  values: item.options
                                };
                  rv.push(comboItem);
                }
              })
            }
        });

  return rv 
}
function getFilloutFormList(allowEmpty:boolean) {
  let rv: { text: string; value: any; disabled: boolean; }[] = []
  if (allowEmpty) {
    rv.push({text:" - ", value: 0, disabled:false});
  }
  store.getters.getQuestioners.forEach((form:Net.FillOutForm) => {
      if (!form.is_deleted) {
          let questionerId = {text: ("[" + form.id.toString() + "] " + form.name),
                          value: form.id,
                          disabled: false};
          rv.push(questionerId);
      }
  });
  return rv;
}
function getUserProfileJSON(id:number) {
  let userData = getUser(id) as Net.Appuser;
  return userData.questioner_json;
}
function getUserProfileFormId(userId:number) {
  let userData = getUser(userId) as Net.Appuser;
  return (userData.is_amigo) ? Net.AmigoProfileFormId : Net.KidProfileFormId;
}
function getGroupOccupationMeetings(meetings:Map<number, Net.MeetingAndInvitees>) {
  let rv = new Array<Net.MeetingAndInvitees>();
  meetings.forEach((value, key) => {
    if (Number(value.monthly_topic_id) > 0)
    {
      rv.push(value);
    }
  })
  return rv.sort((a, b) => a.id - b.id);
}

function sameChatStream(m1:DB.Message, m2:DB.Message) {
  return ((m1.id == m2.id) 
          || (m1.ref_id == m2.id)
          || (m1.id == m2.ref_id)
          || ((m2.ref_id > 0) && (m1.ref_id == m2.ref_id)));
}
function chatHistory(msg:DB.Message, messages:Array<MessageStream>) {
  let rv = new Array<DB.Message>();
  let root = messages.find(m => ((m.id > 0) && (m.streamRoot().id == msg.id)));
  if (root) {
    rv = root.items;
  }
  return rv;
}
function isRequestChat(msg:DB.Message) {
  let msgType = store.getters.nomenclator.messageTypes.find((t:DB.Messagetype) => t.id == msg.type_id)
  return (msgType && msgType.code === Net.MsgType_Request);
}
function isResponseChat(msg:DB.Message) {
  let msgType = store.getters.nomenclator.messageTypes.find((t:DB.Messagetype) => t.id == msg.type_id)
  return (msgType && msgType.code === Net.MsgType_Response);
}

function getRoomTitle(room_id:number) {
  let room = store.getters.getRoom(room_id) as Net.ChatRoomAndMembers;
  if (room) {
      let rv = room.title;
      if (room.title.startsWith(Net.KidAdmigoGroupPrefix) // + kidId.toString();
          && room.unique_id.startsWith(Net.GROUP_ROOM_PREFIX)) // + group.id.toString();
      {
        if (REST.isKid()) {
          rv = Net.PairingRoomChildView;
        } else {
          let kidId = Number(room.title.substr(Net.KidAdmigoGroupPrefix.length))
          rv = Net.PairingRoomAmigoView + userName(kidId);
        }
      }

      if (rv.trim().length == 0) {
        if (room.unique_id.startsWith(Net.PERSONAL_ROOM_PREFIX)) {
          let tags = room.unique_id.split("_");
          for (let t of tags) {
            if (t.startsWith(Net.ROOM_USER_PREFIX)) {
              let userId = Number(t.substr(Net.ROOM_USER_PREFIX.length));
              rv = userName(userId);
              if (userId != REST.userId())
                break;
            }
            if (t.startsWith(Net.ROOM_GROUP_PREFIX)) {
              rv = groupName(Number(t.substr(Net.ROOM_GROUP_PREFIX.length)));
              break;
            }
          }
        }
      }
      return rv;
  }
  return "???";
}

function addDayDivider(messages:Array<DB.Message>) {
  let prevDate : Date = new Date(0);
  let dayDivCount = 0;
  let rv = new Array<DB.Message>()
  messages.forEach(m => {
                    let msgDate = new Date(m.send_time);
                    if ((msgDate.getDate() != prevDate.getDate())
                        || (msgDate.getMonth() != prevDate.getMonth())
                        || (msgDate.getFullYear() != prevDate.getFullYear()))
                    {
                      dayDivCount -= 1;
                      prevDate = msgDate;
                      let dayDiv = new DB.Message();
                      dayDiv.id = dayDivCount;
                      dayDiv.content = dateToStr(prevDate);
                      rv.push(dayDiv);
                    }
                    rv.push(m);
                  })
  return rv;
}

function getRoomMessages(messages:Map<number, DB.Message>,
                        room_id:number)
{
  let doGrouping = true;
  let groupByUser = false;
  let room = store.getters.getRoom(room_id) as Net.ChatRoomAndMembers;
  if (room) {
      doGrouping = !room.unique_id.startsWith(Net.SYSTEM_ROOM_PREFIX);
      groupByUser = REST.isAdmin() && ((room.unique_id === Net.AmigoSysRoom) 
                                      || (room.unique_id === Net.KidSysRoom));
  }

  let rv : Array<MessageStream> = [];
  for (const key of Array.from(messages.keys()).sort((a, b) => a-b)) {
    const value = messages.get(key);
    if (typeof value === "undefined") {
      continue;
    }

    if (value.room_id === room_id) {
      let addedToStream = false;
      if (doGrouping) {
        let sameUserId = 0;
        if (groupByUser) {
          let user = getUser(value.sender_id);
          if (user) {
            if (!(user as Net.Appuser).is_admin) {
              sameUserId = value.sender_id;
            }
          }
        }

        let taggedIds:Array<String> = [];
        if (value.content.startsWith(Net.TAGGING_PREFIX)) {
          let taggingMatch = Net.RE_tagging.exec(value.content);
          if (taggingMatch) {
            taggedIds = taggingMatch[1].split(',');
          }
        }

        for (let m of rv) {
          if ((sameChatStream(value, m))
              || m.hasMessageFromUserId(sameUserId))
          {
            m.addStreamMessage(value, (taggedIds.length < 2));
            addedToStream = true;
            break;
          } else if (groupByUser && (taggedIds.length>0)) {
            for (let uId of taggedIds) {
              if (m.hasMessageFromUserId(Number(uId))) {
                m.addStreamMessage(value, (taggedIds.length < 2));
                if (taggedIds.length == 1) {
                  // If only one user tagged then show it 
                  // only in stream and hide from main chat
                  addedToStream = true;
                  break;
                }
              }
            }
          }
        }
      }

      if (!addedToStream) {
        let rootMsg = new MessageStream(value);
        if (groupByUser) {
          // If earlier messages were tagged to this user
          // then group them here
          for (let m of rv) {
            if (m.content.startsWith(Net.TAGGING_PREFIX)) {
              let taggingMatch = Net.RE_tagging.exec(m.content);
              // REST.logDebug("TAG MATCH:", m.content, taggingMatch);
              if (taggingMatch) {
                let taggedIds = taggingMatch[1].split(',');
                for (let uId of taggedIds) {
                  if (rootMsg.hasMessageFromUserId(Number(uId))) {
                    rootMsg.addStreamMessage(m, false);
                  }
                }
              }
            }
          }
        }
        rv.push(rootMsg);
      }
    }
  }

  // filter messages invisible for kids
  // This would be more optimal to implemented on server side
  // but there is more difficult
  let roomView = new ChatRoomView(room);
  rv = rv.filter(m => roomView.isMessageVisible(m));
  rv.forEach(ms => ms.filterStreamMessages(m => roomView.isMessageVisible(m)));

  if (room.unique_id != Net.NewsRoom) {
    // For all chat rooms except the news room the grouped
    // messages show by the time of last message received
    rv.forEach(a => {
      a.setContent(a.items[a.items.length-1]);
    });
  }

  rv = rv.sort((a, b) => (new Date(a.send_time).valueOf() - new Date(b.send_time).valueOf()));

  return rv;
}

function adminInNewsRoom(roomId:number, isHistoryView:boolean) {
  // room is MESSAGE_BOARD and user is admin and not historyView
  let room = store.getters.getRoom(roomId) as Net.ChatRoomAndMembers;
  return ((!isHistoryView)
          && (room!=null) && (room.unique_id == Net.NewsRoom)
          && REST.isAdmin())
}

function isSystemRoom(room:Net.ChatRoomAndMembers)
{
  return ((room.unique_id == Net.AmigoSysRoom) 
          || (room.unique_id == Net.KidSysRoom)
          || (room.unique_id == Net.NewsRoom)
          || (room.unique_id.startsWith(Net.SYSTEM_ROOM_PREFIX)));
}

function nonSystemRooms(rooms:Map<number, Net.ChatRoomAndMembers>, filter:string)
{
  let rv : Net.ChatRoomAndMembers[] = [];
  rooms.forEach((r:Net.ChatRoomAndMembers) => {
    if (!isSystemRoom(r)
        && ((filter.length == 0)
            || (r.title.toUpperCase().indexOf(filter.toLocaleUpperCase()) >=0 )))
    {
      rv.push(r);
    }
  });

  return rv.sort((a, b) => {
    return a.title.localeCompare(b.title);
  });
};

function getUniqueRoomId(rooms:Map<number, Net.ChatRoomAndMembers>, unique_id:string)
{
  let rv = 0;
  rooms.forEach((r:Net.ChatRoomAndMembers) => {
    if (r.unique_id == unique_id) {
      rv = r.id;
    }
  });
  return rv;
};

function boolCmp(bool1:boolean, bool2:boolean, isDesc:boolean)
{
  if (isDesc) {
    return (bool2 ? 1 : 0) - (bool1 ? 1 : 0);
  }
  return (bool1 ? 1 : 0) - (bool2 ? 1 : 0);
}
function strCmp(str1:string, str2:string, isDesc:boolean)
{
  // to ignore accents
  // REST.logInfo(str1.localeCompare(str2, 'hu', { sensitivity: 'base' })); // 0
  if (isDesc) {
    return str1.localeCompare(str2);
  }
  return str2.localeCompare(str1);
}
function sortTasks(items:Net.TaskAndAssignee[], sortBy: string[], sortDesc: boolean[]) {
  if ((sortBy.length == 0) || (sortDesc.length == 0)) {
    return items;
  }

  let index = sortBy[0];
  let isDesc = sortDesc[0];
  items.sort((a, b) => {
    if (index == "update_time") {
      return strCmp(dateToStr(a.update_time), dateToStr(b.update_time), isDesc);
    } else if (index == "dead_line") {
      return strCmp(dateToStr(a.dead_line), dateToStr(b.dead_line), isDesc);
    } else if (index == "reply_date") {
      return strCmp(getReplyDateForTask(a), getReplyDateForTask(b), isDesc);
    } else if (index == "updater_id") {
      return strCmp(getGroupOrUserName(a.updater_id, false)
                        , getGroupOrUserName(b.updater_id, false)
                        , isDesc);
    } else if (index == "is_active") {
      return boolCmp(a.is_active, b.is_active, isDesc);
    } else if (index == "is_cancelled") {
      return boolCmp(a.is_active, b.is_active, isDesc);
    } else if (index == "title") {
      return strCmp(a.title, b.title, isDesc);
    } else if (index == "reply") {
      return strCmp(getReplyForTask(a), getReplyForTask(b), isDesc);
    }
    REST.logError(" sortTasks Unknown index:", index);
    return 0;
  })
  return items;
}
function getUser(id:number) {
  let user = store.getters.getUser(id);
  if (user) {
      return user;
  }
  store.dispatch('updateUser', id);
  return null;
}
function userPresent(id:number) {
    let user = getUser(id);
    if (user) {
        return user.is_present;
    }
    return false;
}
function userName(id:number) {
    let user = getUser(id) as Net.Appuser;
    if (user) {
        if ((user.family_name.length > 0)
          || (user.given_name.length > 0))
        {
          return user.given_name + " " + user.family_name;
        }
        if (user.name.length > 0) {
          return user.name;
        }
        return user.login;
    }
    return "";
}
function getGroupOrUserName(userId:number, isGroup:boolean) {
  if (isGroup) {
    return groupName(userId);
  } else {
    return userName(userId);
  }
}
function userPhone(id:number) {
    let user = getUser(id) as Net.Appuser;
    if (user) {
        return user.phone;
    }
    return "";
}
function userEmail(id:number) {
    let user = getUser(id) as Net.Appuser;
    if (user) {
        return user.email;
    }
    return "";
}
function getUserAvatarLink(id:number) {
  let user = getUser(id);
  if (user) {
    if (user.avatar_doc_id != 0){
      const avatarUrl = REST.documentLink(user.avatar_doc_id);
      return avatarUrl;
    }
  }
  return require('../assets/logo.png');
}

function getDocuments(type:string, meetingId:number, eventDocuments:Array<DB.Document>)
{
  REST.call("GET", "/docs/" + type + "/" + meetingId,
            null,
            (r => { // REST.logDebug("eventDocuments:", r);
                r.detail.forEach((doc:DB.Document) => {
                    eventDocuments.push(doc);
                })
                return r.detail;
            }),
            (async (r) => { 
                REST.logError(" eventDocuments:", r);
            }));
}
function getMeetingTasks(meetingId:number)
{
  let rv = new Array<Net.TaskAndAssignee>()
  let tasks =  Array.from((store.getters.getTasks as Map<number, Net.TaskAndAssignee>).values());
  tasks.forEach(task => {
    if (task.meeting_link == meetingId) {
        rv.push(task);
    }
  });
  return rv;
}
function getMeetingDocuments(meetingId:number, eventDocuments:Array<DB.Document>)
{
  getDocuments("meeting", meetingId, eventDocuments);
}
function getTaskReplyDocuments(taskReplyId:number, replyDocuments:Array<DB.Document>)
{
  getDocuments("taskreply", taskReplyId, replyDocuments);
}
function getMapFromString(str:string)
{
    let rv = new Map<string, string>()
    if (str.trim().length > 0) {
        let entries = JSON.parse(str);
        rv = new Map(entries);
        // REST.logDebug("NEWVal:", newVal, this.$data.filloutValues)
    }
    return rv;
}
function validateQuestioner(questionerId: number, replyStr:string)
{
  let rv = false;
  let questioner = store.getters.getQuestioner(questionerId) as Net.FillOutForm;
  let replyMap = getMapFromString(replyStr)
  if (questioner) {
      rv = true;
      questioner.items.forEach(i => {
        let relyVal = replyMap.get(i.name);
        if (i.mandatory && 
          (!relyVal || (relyVal.trim().length==0)))
        {
          rv = false;
        }
      })
  }
  return rv;
}
function getTopicDocuments(meetingId:number, eventDocuments:Array<DB.Document>)
{
  getDocuments("topic", meetingId, eventDocuments);
}
function getGroup(id:number) {
    let group = store.getters.getGroup(id);
    if (group) {
        return group;
    }
    // REST.logDebug("updateGroup:", id)
    store.dispatch('updateGroup', id);
    return null;
}
function groupName(id:number) {
    let group = getGroup(id);
    if (group) {
        return group.name;
    }
    return "";
}
function isReadonlyGroup(item: Net.UserGroupAndMembers) {
  return (item.id > 0) && ((item.name == Net.AdminsGroupName) || (item.name == Net.AdmigosGroupName));
}
function sameAssignee(a:DB.Taskassignee, b:DB.Taskassignee)
{
  return ((a.member_id == b.member_id) 
          && (a.is_member_group == b.is_member_group));
}
function hasRepliedIcon(userId:number, isGroup:boolean,
                        replies:Array<DB.Taskreply>)
{
  if (!isGroup) {
    if (-1 != replies.findIndex(r => r.user_id == userId))
      return "mdi-check-circle"
    return "mdi-checkbox-blank-circle-outline"
  }
  return "mdi-minus";
}
function isAssigned(item: DB.Taskassignee,
                    new_assignees: Array<DB.Taskassignee>,
                    del_assignees: Array<DB.Taskassignee>,
                    editedItem: Net.TaskAndAssignee)
{
  if (del_assignees.find(m => DATA.sameAssignee(m, item)))
  {
      return false;
  }
  if (new_assignees.find(m => DATA.sameAssignee(m, item))
      || (editedItem.assignees.find(m => DATA.sameAssignee(m, item))))
  {
      return true;
  }
  return false;
}
function getAssignedTasks()
{
  let rv = new Array<Net.TaskAndAssignee>();
  let tasks =  Array.from((store.getters.getTasks as Map<number, Net.TaskAndAssignee>).values());
  tasks.forEach(task => {
    let matched = false;
    for (let a of task.assignees) {
      if ((!a.is_member_group)
          && (REST.userId() == a.member_id))
      {
        // REST.logInfo(REST.userId(), " Assigned ", a.member_id)
        rv.push(task);
        matched = true;
        break;
      } else if ((a.is_member_group)
                && (isUserMemberOfGroup(REST.userId(), a.member_id)))
      {
        // REST.logInfo(REST.userId(), " Group member ", a.member_id)
        rv.push(task);
        matched = true;
        break;
      }
    }
    if (!matched) {
      for (let r of task.replies) {
        if (REST.userId() == r.user_id)
        {
          // REST.logInfo(REST.userId(), " Replied ", r.user_id)
          rv.push(task);
          matched = true;
          break;
        }
      }
    }
  });
  return rv;
}
function getReplyForTask(task:Net.TaskAndAssignee)
{
  let rv = "";
  let taskType = DATA.getNomenIdCode(store.getters.nomenclator.taskTypes, task.type_id);
  for (let r of task.replies) {
      if (REST.userId() == r.user_id)
      {
          if (taskType == Net.TaskReplyType_FillOut) {
            rv = "..."
          } else {
            rv = r.reply;
          }
          break;
      }
  }
  return rv;
}
function isTaskAssignedToMe(task:Net.TaskAndAssignee)
{
  for (let r of task.assignees) {
      if (!r.is_member_group && (REST.userId() == r.member_id))
      {
          return true;
      }
      if (r.is_member_group) {
        let group = getGroup(r.member_id) as Net.UserGroupAndMembers;
        if (group) {
          for (let m of group.members) {
            if (REST.userId() == m.user_id) {
              return true;
            }
          }
        }
      }
  }
  return false;
}
function getReplyDateForTask(task:Net.TaskAndAssignee) {
  let rv = "";
  for (let r of task.replies) {
      if (REST.userId() == r.user_id)
      {
          rv = DATA.dateToStr(r.reply_time);
          break;
      }
  }
  return rv;
}
function groupHasOnlyKids(groupId:number)
{
  let rv = false;
  let group = store.getters.getGroup(groupId) as Net.UserGroupAndMembers;
  if (group && group.members.length>0) {
    rv = true;
    for(let m of group.members) {
      let user = getUser(m.user_id) as Net.Appuser;
      if (!user || !user.is_kid) {
        rv = false;
        break;
      }
    }
  }
  return rv;
}

function getTaskPossibleAssignees(forKids:boolean)
{
  let rv = new Array<DB.Taskassignee>();
  let idCounter = 0;
  store.getters.getUsers.forEach((user:Net.Appuser) => {
    let assignee = new DB.Taskassignee();
    idCounter += 1;
    assignee.id = idCounter;
    assignee.is_member_group = false;
    assignee.member_id = user.id;
    if (!forKids && (user.is_amigo == true)) {
      rv.push(assignee);
    }
    if (forKids && (user.is_kid == true)) {
      rv.push(assignee);
    }
  });

  store.getters.getGroups.forEach((group:Net.UserGroupAndMembers) => {
      if ((!forKids && !groupHasOnlyKids(group.id))  
          || (forKids && groupHasOnlyKids(group.id)))
      {
        let assignee = new DB.Taskassignee();
        idCounter += 1;
        assignee.id = idCounter;
        assignee.is_member_group = true;
        assignee.member_id = group.id;
        rv.push(assignee);
      }
    });

  return rv;
}
function sameInvitee(a:DB.Meetinginvitee, b:DB.Meetinginvitee)
{
  return ((a.member_id == b.member_id) 
          && (a.is_member_group == b.is_member_group));
}
function filterUser(user: DB.Appuser, search:string)
{
  let s = search.toUpperCase();
  return user.name.toUpperCase().includes(s) ||
          user.login.toUpperCase().includes(s) ||
          user.email.toUpperCase().includes(s) ||
          user.phone.toUpperCase().includes(s) ||
          user.family_name.toUpperCase().includes(s) ||
          user.given_name.toUpperCase().includes(s);
}
function filterInvitee(invitee: DB.Meetinginvitee, search:string)
{
  if (invitee.is_member_group) {
    let s = search.toUpperCase();
    let group:Net.UserGroupAndMembers = getGroup(invitee.member_id);
    return group.name.toUpperCase().includes(s);
  }

  let user:DB.Appuser = getUser(invitee.member_id);
  return filterUser(user, search);
}
function getUserType(userId:number)
{
  let user = DATA.getUser(userId) as DB.Appuser;
  if (user) {
    if (user.is_kid) {
        return 'invitee_is_kid'
    }
    if (user.is_amigo) {
      if (user.is_admin) {
        return 'invitee_is_admin_amigo';
      }
      return 'invitee_is_amigo'
    }
    if (user.is_admin) {
      return 'invitee_is_admin'
    }
  }
  return "invitee_is_none";
}
function getInviteeType(item: DB.Meetinginvitee)
{
  if (item.is_member_group) {
    return 'invitee_is_group'
  }
  return getUserType(item.member_id);
}
function isInvited(item: DB.Meetinginvitee, 
                  invitees:Array<KeyedInvitee>)
{
  return invitees.find(m => DATA.sameInvitee(m.data, item));
}
function getGroupPossibleMembers(isAmigoOnly:boolean)
{
  let rv = new Array<DB.Meetinginvitee>();
  let idCounter = 0;
  store.getters.getUsers.forEach((user:Net.Appuser) => {
    if (!isAmigoOnly || (user.is_amigo == true)) {
      let invitee = new DB.Meetinginvitee();
      idCounter += 1;
      invitee.id = idCounter;
      invitee.is_member_group = false;
      invitee.member_id = user.id;
      rv.push(invitee);
    }
  });
  return rv;
}

function getMeetingPossibleInvitees(meeting:Net.MeetingAndInvitees)
{
  let rv = new Array<DB.Meetinginvitee>();
  let idCounter = 0;
  let isUserKid = REST.isKid();
  let userId = REST.userId();
  store.getters.getUsers.forEach((user:Net.Appuser) => {
    let invitee = new DB.Meetinginvitee();
    idCounter += 1;
    invitee.id = idCounter;
    invitee.is_member_group = false;
    invitee.member_id = user.id;
    if (isUserKid) {
      // Kids cannot invite groups or other kids
      if ((userId == user.id)
          || (user.is_amigo == true))
      {
        rv.push(invitee);
      }
    } else {
      if ((meeting.monthly_topic_id == 0)
        || (user.is_amigo == true))
      {
        rv.push(invitee);
      }
    }
  });

  if ((!isUserKid) && (meeting.monthly_topic_id == 0)) {
    // Only none-monthly topic meetings created
    // by amigos can invite groups
    store.getters.getGroups.forEach((group:Net.UserGroupAndMembers) => {
      let invitee = new DB.Meetinginvitee();
      idCounter += 1;
      invitee.id = idCounter;
      invitee.is_member_group = true;
      invitee.member_id = group.id;
      rv.push(invitee);
    });
  }

  // REST.logDebug("getMeetingPossibleInvitees", rv);
  return rv;
}
function isUserMemberOfGroup(uid:number, gid:number) {
  let grp = (store.getters.getGroup(gid) as Net.UserGroupAndMembers)
  if (grp) {
    return (grp.members.findIndex(m => m.user_id == uid) != -1);
  }
  store.dispatch('updateGroup', gid); 
  return false;
};
function meetingChatRoomId(meeting:Net.MeetingAndInvitees) {
  let rv = 0;
  let uId = Net.MEETING_ROOM_PREFIX + meeting.id;
  let rooms = store.getters.getRooms as Map<number, Net.ChatRoomAndMembers>;
  rooms.forEach(v => {
    if (v.unique_id == uId) {
      rv = v.id;
    }
  })
  return rv;
}
function isMeetingInvitee(uid:number, meeting:Net.MeetingAndInvitees) {
  return meeting.invitees.findIndex(i => {  if (i.is_member_group) {
                                              return isUserMemberOfGroup(uid, i.member_id);
                                            } else {
                                              return (i.member_id == uid)
                                            }
                                    }) != -1;
}
function isMeetingParticipant(uid:number, meeting:Net.MeetingAndInvitees) {
  return meeting.participants.findIndex(i => i.user_id == uid) != -1;
}
function getParticipationState(uid:number, meeting:Net.MeetingAndInvitees) {
  let rv = 0;
  meeting.participants.forEach(p => { if (p.user_id == uid) {
                                              rv = p.state_id;
                                        }
                                });
  return rv;
}
function hasUserAcceptedMeeting(uid:number, meeting:Net.MeetingAndInvitees) {
  let pIdx = meeting.participants.findIndex(i => i.user_id == uid);
  if (pIdx != -1) {
    return meeting.participants[pIdx].state_id == acceptedTypeId();
  }
  return false;
}
function getKidParticipantsCount(meeting:Net.MeetingAndInvitees) {
  let participantCount = 0;
  let acceptId = acceptedTypeId();
  meeting.participants.forEach(p => {
    if (p.state_id == acceptId)
    {
      let user = getUser(p.user_id) as DB.Appuser;
      if ((user) && user.is_kid) {
        participantCount += 1;
      }
    }
  })
  return participantCount;
}
function getAmigoParticipantsCount(meeting:Net.MeetingAndInvitees) {
  let participantCount = 0;
  let acceptId = acceptedTypeId();
  meeting.participants.forEach(p => {
    if (p.state_id == acceptId)
    {
      let user = getUser(p.user_id) as DB.Appuser;
      if ((user) && user.is_amigo) {
        participantCount += 1;
      }
    }
  })
  return participantCount;
}

var topicColors = ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange'];

function getMeetingColor(meeting:Net.MeetingAndInvitees) {
  if (meeting.id > 0) {
    // REST.logDebug("getMeetingColor:", meeting, new Error().stack)
    store.dispatch("updateMeeting", meeting.id);
  }

  // Meetings with no more places are grayed out
  if (REST.isKid() && meeting.max_child_cnt > 0) {
    if (!hasUserAcceptedMeeting(REST.userId(), meeting)) {
      let curKidCnt = getKidParticipantsCount(meeting);
      if (curKidCnt >= meeting.max_child_cnt) {
        return 'grey lighten-2';
      }
    }
  }

  let uid = REST.userId();
  if (-1 != meeting.participants.findIndex(p => (uid == p.user_id) && (p.state_id == acceptedTypeId())))
  {
    // accepted meeting
    return 'green darken-4';
  }
  if (-1 != meeting.participants.findIndex(p => (uid == p.user_id) && (p.state_id == declinedTypeId())))
  {
    // declined meeting
    return 'red lighten-4';
  }
  if (-1 != meeting.participants.findIndex(p => (uid == p.user_id) && (p.state_id == tentativeTypeId())))
  {
    return 'purple darken-2';
  }

  if (meeting.monthly_topic_id > 0) {
    let topic = store.getters.getTopics.get(Number(meeting.monthly_topic_id));
    if (!topic) {
      REST.logError(" Unknown TOPIC ID:", meeting.monthly_topic_id, topic, store.getters.getTopics);
      return 'grey lighten-2';
    }
    if (!(topic as DB.Monthlytopic).is_active) {
      return 'grey darken-1';
    }
    return topicColors[(meeting.monthly_topic_id) % topicColors.length]
  }

  return 'cyan darken-1';
}

function checkMeetingUsers(meeting:Net.MeetingAndInvitees)
{
  meeting.invitees.forEach(i => {
    if (i.is_member_group) {
      store.dispatch('updateGroup', i.member_id);
    } else {
      store.dispatch('updateUser', i.member_id);
    }
  })
  meeting.participants.forEach(p => {
    store.dispatch('updateUser', p.user_id);
  })
}

function isKidAmigoPair(kidId:number, amigoId:number)
{
  let rv = false;
  store.getters.getPairs.forEach((p:DB.Amigoskids) => {
    if ((p.amigo_id == amigoId) && (p.kid_id == kidId)) {
      rv = true;
    }
  });
  return rv;
}
function areGroupMembers(uid1:number, uid2:number)
{
  let rv = false;
  store.getters.getGroups.forEach((p:Net.UserGroupAndMembers) => {
    let found1 = false;
    let found2 = false;
    p.members.forEach(m => {
      if (m.user_id == uid1) {
        found1 = true;
      } else if (m.user_id == uid2) {
        found2 = true;
      }
    })
    if (found1 && found2) {
      rv = true;
    }
  });
  return rv;
}
function getPossibleChatInvitees()
{
  let possibleInvitees = new Array<{member_id:number, is_member_group:boolean}>();
  store.getters.getUsers.forEach((u:Net.Appuser) => {
      if (REST.isKid()) {
        if (DATA.isKidAmigoPair(REST.userId(), u.id)) {
          possibleInvitees.push({member_id:u.id, is_member_group:false});  
        }
      } else if (REST.isAdmin()) {
        possibleInvitees.push({member_id:u.id, is_member_group:false});
      } else if (u.is_kid) {
        if (DATA.isKidAmigoPair(u.id, REST.userId()))
        {
          possibleInvitees.push({member_id:u.id, is_member_group:false});
        }
      } else if (areGroupMembers(REST.userId(), u.id)) {
          possibleInvitees.push({member_id:u.id, is_member_group:false});
      }
  });
  if (REST.isAdmin()) {
    store.getters.getGroups.forEach((u:Net.UserGroupAndMembers) => {
        possibleInvitees.push({member_id:u.id, is_member_group:true});
    });
  }
  return possibleInvitees;
}
function getMeetingTitle(event:Net.MeetingAndInvitees)
{
  let eventName = event.title;
  if ((eventName.length == 0) && (event.monthly_topic_id > 0)) {
    store.dispatch('updateTopics');
    let topic = store.getters.getTopics.get(Number(event.monthly_topic_id));
    if (topic) {
      eventName = topic.title;
    }
  }
  return eventName;
}
function hideMeeting(m:Net.MeetingAndInvitees, meetings:Array<Net.MeetingAndInvitees>)
{
  let rv = false;
  if (REST.isKid()) {
    if (m.monthly_topic_id > 0) {
      meetings.forEach((v: Net.MeetingAndInvitees, k:number) => {
        if ((v.id != m.id)
            && (v.monthly_topic_id == m.monthly_topic_id)
            && (v.participants.find(p => p.user_id==REST.userId() && p.state_id==acceptedTypeId())))
        {
          // Other meeting for same monthly topic already accepted by kid
          rv = true
        }
      })
    }
    /* /// Uncomment if meetings with no places left should not be visible for kids
    if (m.max_child_cnt > 0) {
      if (!hasUserAcceptedMeeting(REST.userId(), m)) {
        let curKidCnt = getKidParticipantsCount(m);
        if (curKidCnt >= m.max_child_cnt) {
          // No more kids can register
          rv = true;
        }
      }
    }
    */
  }
  return rv;
}
function meetingsForRange(meetings:Array<Net.MeetingAndInvitees>, from:any, to:any)
{
  let events : any[] = [];
  const minDate = new Date(`${from.date}T00:00:00`)
  const maxDate = new Date(`${to.date}T23:59:59`)

  meetings.forEach((v: Net.MeetingAndInvitees, k:number) => {
        if (v.is_cancelled == false) {
          let startTime = new Date(v.start_time);
          let endTime = new Date(v.end_time);
          // TODO: handle recurrences!
          if ((endTime >= minDate) && (startTime <= maxDate)) {
            if (!hideMeeting(v, meetings)) {
              let eventName = "[" + dateToTimeStrNoSec(startTime) + "]"
              if (v.max_amigo_cnt > 0) {
                eventName += "{" + getAmigoParticipantsCount(v).toString()
                             + "/" + v.max_amigo_cnt.toString() + "}";
              }
              if (v.max_child_cnt > 0) {
                eventName += "(" + getKidParticipantsCount(v).toString()
                             + "/" + v.max_child_cnt.toString() + ")";
              }
              eventName += " " + getMeetingTitle(v);

              events.push({
                meeting_id: v.id,
                monthly_topic_id: v.monthly_topic_id,
                name: eventName,
                start: startTime,
                end: endTime,
                meeting_details: v,
                timed: false, // "is whole day meeting"
              })
            }
          }
        }
    });

  return events;
}

function hasAcceptedIcon(userId:number, isGroup:boolean,
                        participants:Array<DB.Meetingparticipant>)
{
  let rv = "mdi-minus"
  if (!isGroup) {
    participants.forEach(p => {
          if (p.user_id == userId) {
            if (p.state_id == acceptedTypeId()) {
              rv = "mdi-account-check"
            } else if (p.state_id == tentativeTypeId()) {
              rv = "mdi-account-question"
            } else if (p.state_id == declinedTypeId()) {
              rv = "mdi-account-remove"
            } else {
              rv = "mdi-account-clock"
            }
          }
        });
  }
  return rv;
}

function getTableIcon(userId:number, isGroup:boolean,
                      isIt:(u:Net.Appuser) => boolean)
{
  let rv = "mdi-minus"
  if (!isGroup) {
    let user = getUser(userId) as Net.Appuser;
    if (user) {
      if (isIt(user)) {
        rv = "mdi-checkbox-marked"
      } else {
        rv = "mdi-checkbox-blank-outline"
      }
    } else {
      REST.logError(" tableIcon:null", userId);
      rv = "mdi-cloud-question"
    }
  }
  return rv;
}

function getGroupsOfUserId(uid:number) {
  let rv : Array<Number> = [];
  // REST.logDebug("getGroupsOfUserId, id=", uid, ", groups=", store.getters.getGroups)
  store.getters.getGroups.forEach((group_instance:Net.UserGroupAndMembers) => {
    const m = group_instance.members.find(m => m.user_id == uid);
    if (m){
      rv.push(m.group_id);
    }
  })
  return rv;
};
